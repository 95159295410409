export default {
    "project.global.exceptions.NetworkError": 'Could not connect to server',
    'project.app.Header.viewer': 'Geographical viewer',
    'project.app.Header.geometricElements': 'Tables of {plural}',
    'project.app.Header.admin': 'Administration',
    'project.app.Header.admin.users': 'Users',
    'project.app.Header.admin.elementType': 'Type of {plural}',
    'project.app.Header.admin.attributeType': 'Attribute types',
    'project.app.Header.admin.configurationParameters': 'Configuration parameters',
    'project.app.Header.admin.layer': 'Layers',
    'project.app.Header.admin.layerGroup': 'Layer groups',
    'project.app.Header.admin.attachedFile': 'Attached files',
    'project.app.Header.admin.geometricElementStyles': 'Styles for {plural}',
    'project.app.Header.myContributions': '{myElements}',
    'project.app.Header.addGeometricElement': 'Create {singular}',
    'project.app.Header.login': 'Login',
    'project.app.Header.logout': 'Logout',
    'project.app.Header.signUp': 'Sign Up',
    'project.app.Header.password': 'Password',
    'project.app.Header.downloadAPK': 'Download Android application',
    'project.app.Body.search': 'Search',
    'project.app.Body.accept': 'Accept',
    'project.app.Body.cancel': 'Cancel',
    'project.app.Body.back': 'Back',
    'project.app.Body.next': 'Next',
    'project.app.Body.save': 'Save',
    'project.app.Body.return': '← Back to home',
    'project.app.Body.field.required': 'Field "{field}" is required',
    'project.app.Body.field.positive': 'Field "{field}" must be positive',

    'project.configurationParameter.name': 'Name',
    'project.configurationParameter.code': 'Code',
    'project.configurationParameter.value': 'Value',
    'project.configurationParameter.userInterface': 'Is user interface?',
    'project.configurationParameter.noDataIndication': 'There are no configuration parameters',
    'project.configurationParameter.modify': 'Modify parameter "{parameter}"',
    'project.configurationParameter.modify.defaultLanguage': 'Default language',
    'project.configurationParameter.required': 'Value cannot be null',
    'project.configurationParameter.delete': 'Delete value',
    'project.configurationParameter.delete.message': 'Do you want to delete the value of configuration parameter {configurationParameter}?',

    'project.common.close': 'Close',
    'project.common.ErrorDialog.title': 'Error',
    'project.common.permissionDenied': 'You do not have permission to access this page',
    'project.common.mustLogin': 'You must be logged in to access this page',
    'project.common.action': 'Actions',
    'project.common.modify': 'Modify',
    'project.common.center': 'Center',
    'project.common.modifyOnViewer': 'Modify on geographical viewer',
    'project.common.centerOnViewer': 'See on map',
    'project.common.selectOneOption': 'Select one option',
    'project.common.viewElements': 'View {plural}',
    'project.common.attributes': 'Attributes',
    'project.common.delete': 'Delete',
    'project.common.deleteSomething': 'Delete {something}',
    'project.common.download': 'Download',
    'project.common.viewFiles': 'View photos',
    'project.common.deleteFile': 'Delete file',
    'project.common.addFilesButton': 'Add files',
    'project.common.addFiles': 'Add files to {singular} {id}',
    'project.common.yes': 'Yes',
    'project.common.no': 'No',
    'project.common.filters': 'Filters',
    'project.common.filters.clear': 'Delete all filters',
    'project.common.filepond.labelIdle': 'Drag and drop your files or <span class="filepond--label-action">Browse</span>',
    'project.common.filepond.labelTapCancel': 'Tap to cancel',
    'project.common.filepond.labelLoading': 'Loading',
    'project.common.filepond.labelWaitForSize': 'Waiting for size',
    'project.common.tables.totalElements': 'Showing from {from} to {to} of {total} ',
    'project.common.tables.filter.text.placeholder': 'Search {column}',
    'project.common.tables.filter.select.placeholder': 'Select {column}',
    'project.common.location': 'Location',
    'project.common.location.province': 'Province',
    'project.common.location.municipality': 'Municipality',
    'project.common.location.parish': 'Parish',
    'project.common.dates': 'Dates',
    'project.common.showDetails': 'View in detail',
    'project.common.pagination.firstPageTitle': 'First page',
    'project.common.pagination.lastPageTitle': 'Last page',
    'project.common.pagination.nextPageTitle': 'Next page',
    'project.common.pagination.prePageTitle': 'Previous page',
    'project.common.legendType': 'Legend type',
    'project.common.legendType.url': 'Legend URL',
    'project.common.legendType.file': 'Image file',
    'project.common.legendType.file.error': 'You must select a file',
    'project.common.legendType.file.existent': 'Existing image',
    'project.common.legendType.file.unexistent': 'New image',
    'project.common.images.error': 'Unable to load image',

    'project.elements.data': 'Data',
    'project.elements.new': 'Create {element}',
    'project.elements.myElements.message': "This table shows {plural} which were published",
    'project.elements.anonymous.message': "You must {link} to create {plural}",
    'project.elements.noElements': 'There are no {plural} for the selected type',
    'project.elements.types.noElements': 'There are no type of {plural}',
    'project.elements.map.zoomInTipLabel': 'Zoom in',
    'project.elements.map.zoomOutTipLabel': 'Zoom out',
    'project.elements.map.fitToExtentTip': 'See the maximum extension',
    'project.elements.map.zoomToExtentTip': 'Zoom by box',
    'project.elements.map.popupTip': 'Consult information about {plural} by clicking',
    'project.elements.map.measureLengthTip': 'Measure distances',
    'project.elements.map.measureAreaTip': 'Measure areas',
    'project.elements.map.addElementTip': 'Creeate {singular}',
    'project.elements.map.fullScreenTip': 'Full screen',
    'project.elements.map.layersTip.show': 'Show layers',
    'project.elements.map.layersTip.hide': 'Hide layers',
    'project.elements.map.legendTip': 'Legend',
    'project.elements.map.otherLayers': 'Other layers',
    'project.elements.map.elements': '{plural}',
    'project.elements.map.provinces': 'Provinces/Distritos',
    'project.elements.map.councils': 'Councils/Concelhos',
    'project.elements.map.parishes': 'Parishes/Freguesias',
    'project.elements.map.entityPopulations': 'Entity populations',
    'project.elements.map.counties': 'Counties',
    'project.elements.map.selectProvince': '-- Select province --',
    'project.elements.map.selectCouncil': '-- Select council --',
    'project.elements.map.selectParish': '-- Select parish --',
    'project.elements.map.selectEntityPopulation': '-- Select entity population --',
    'project.elements.map.searchEntityPopulation': 'Search',
    'project.elements.map.progressiveCenter': 'Progressive search',
    'project.elements.map.searchCenter': 'Direct search',
    'project.elements.map.searchType': 'Search type',
    'project.elements.map.opacityLabelTip': 'Opacity',
    'project.elements.map.exportPDFTip': 'Export to PDF',
    'project.elements.map.exportPDF': 'Export to PDF',
    'project.elements.map.exportPDF.size': 'Page size',
    'project.elements.map.exportPDF.size.a4': 'A4',
    'project.elements.map.exportPDF.size.a3': 'A3',
    'project.elements.map.exportPDF.orientation': 'Orientation',
    'project.elements.map.exportPDF.orientation.landscape': 'Landscape',
    'project.elements.map.exportPDF.orientation.portrait': 'Portrait',
    'project.elements.map.exportPDF.includeLegend': 'Include legend',
    'project.elements.map.exportPDF.includeScale': 'Include scale',
    'project.elements.map.exportPDF.save': 'Export',
    'project.elements.map.exportPDFError': 'An error occurred while generating the PDF',
    'project.elements.map.popupControl.failedLayers': 'Layers could not be consulted: {failedLayers}',
    'project.elements.map.popupControl.select.placeholder': 'Select',
    'project.elements.map.popupControl.select.noOptionsText': 'No options found',
    'project.elements.map.popupControl.select.noSearchResultsText': 'No results found',
    'project.elements.map.popupControl.select.selectAllText': 'Select all',
    'project.elements.map.popupControl.select.searchPlaceholderText': 'Search...',
    'project.elements.map.popupControl.select.optionsSelectedText': 'options selected',
    'project.elements.map.popupControl.select.optionSelectedText': 'option selected',
    'project.elements.map.popupControl.select.allOptionsSelectedText': 'All',
    'project.elements.map.popupControl.select.clearButtonText': 'Clear selection',
    'project.elements.map.popupControl.select.moreText': 'more...',
    'project.elements.map.popupControl.noResult': 'No objects were found at that point',
    'project.elements.map.bookmarkControl.title': 'Bookmarks',
    'project.elements.map.bookmarkControl.placeholder': 'Add new bookmark...',
    'project.elements.modify': 'Modify {element}',
    'project.elements.modify.error': 'Unable to start modification',
    'project.elements.selectOneOption': 'Select one option',
    'project.elements.files': 'Associated files',
    'project.elements.files.hideShow': 'Show/Hide associated files',
    'project.elements.files.noFiles': 'No associated files were found',
    'project.elements.values': 'Values',
    'project.elements.name': 'Name',
    'project.elements.styles': 'Styles',
    'project.elements.styles.show': 'Preview',
    'project.elements.styles.point': 'Point styles',
    'project.elements.styles.point.add': 'Add point style',
    'project.elements.styles.polygon': 'Polygon styles',
    'project.elements.styles.polygon.add': 'Add polygon style',
    'project.elements.styles.line': 'Line styles',
    'project.elements.styles.line.add': 'Add line style',
    'project.elements.styles.delete.message': 'Are you sure you want to remove the style "{style}"?',
    'project.elements.styles.name': 'Style name',
    'project.elements.styles.file': 'Style file',
    'project.elements.geometryType': 'Geometry type',
    'project.elements.pointStyleName': 'Point style name',
    'project.elements.lineStyleName': 'Line style name',
    'project.elements.polygonStyleName': 'Polygon style name',
    'project.elements.description': 'Description',
    'project.elements.geom': 'Geometry',
    'project.elements.geom.LineString': 'Line',
    'project.elements.geom.Point': 'Point',
    'project.elements.geom.Polygon': 'Polygon',
    'project.elements.geom.LinearRing': 'Linear Ring',
    'project.elements.geom.MultiPoint': 'Multipoint',
    'project.elements.geom.MultiLineString': 'MultiLine',
    'project.elements.geom.MultiPolygon': 'Multipolygon',
    'project.elements.geom.Circle': 'Circle',
    'project.elements.files.filename': 'Name',
    'project.elements.files.format': 'Format',
    'project.elements.files.type': 'Mime type',
    'project.elements.files.size': 'Size',
    'project.elements.values.attribute': 'Attribute',
    'project.elements.values.value': 'Value',
    'project.elements.values.order': 'Order',
    'project.elements.creationDate': 'Introduction',
    'project.elements.modificationDate': 'Last modification',
    'project.elements.lessCreationDate': 'Introduction date equal or before than',
    'project.elements.lessModificationDate': 'Modification date equal or before than',
    'project.elements.moreCreationDate': 'Introduction date equal or later than',
    'project.elements.moreModificationDate': 'Modification date equal or later than',
    'project.elements.isPublic': 'Is public?',
    'project.elements.isReviewed': 'Is reviewed?',
    'project.elements.internalComments': 'Internal obs.',
    'project.elements.owner': 'Author',
    'project.elements.type.add': 'Add type of {singular}',
    'project.elements.type.add.name': 'Name',
    'project.elements.type.modify': 'Modify type of {singular}',
    'project.elements.type.modify.name': 'Name',
    'project.elements.type.add.attribute': 'Attribute',
    'project.elements.type.showAttributes': 'Show/Hide associated attributes',
    'project.elements.delete.message': 'You\'re going to delete "{element}", Do you want to continue?',
    'project.elements.geometricElement.delete.message': `You're going to delete "{element}", Do you want to continue? 
    {alert}`,
    'project.elements.geometricElement.delete.message.alert': 'When deleting, its various relationships and its associated files will also be deleted',
    "project.elements.files.fileNoExistsHeader": 'File does not exist',
    "project.elements.files.fileNoExistsBody": 'File not found',
    'project.elements.legend': 'Legend',
    'project.elements.legend.title': 'Legend of type of {singular} {element}',
    'project.elements.legend.show': 'Show legend',
    'project.elements.opacity': 'Opacity',
    'project.elements.opacity.defaultOpacity': 'Layer initial opacity',
    'project.elements.opacity.defaultOpacity.help': 'Value must be between 0 and 100. If no value is specified, the layer will be displayed with an opacity of 100%',
    'project.elements.defaultOpacity.error': 'Field "Layer initial opacity" value must be between 0 and 100',
    'project.elements.opacity.allowChangeOpacity.table': 'Show control on viewer',
    'project.elements.opacity.allowChangeOpacity.form': 'Show opacity change control on viewer',
    'project.elements.type.join.canJoin': 'Allow to join {plural} of this type',
    'project.elements.type.join.tooltipText': 'Text for the insertion form title',
    'project.elements.type.join.tableText': 'Text for the table title',
    'project.elements.join.isPublic': 'Publish?',
    'project.elements.join.isPublic.help.user': 'If it is not published, only you and the administrators will be able to see it',
    'project.elements.join.isPublic.help.admin': 'If it is not published, only the administrators and the creator will be able to see it',
    'project.elements.join.isReviewed': 'Is reviewed?',
    'project.elements.join.comments': 'Comments',
    'project.elements.join.user': 'User',
    'project.elements.join.noJoin': 'No users',
    'project.elements.join.anonymous.message': 'You must {link} to perform this operation',
    'project.elements.join.delete.message': 'Are you sure?',

    'project.elements.attributes.noElements': 'There are no attribute types',
    'project.elements.attributes.dataType': 'Data type',
    'project.elements.attributes.dataType.pick': 'Select the data type from the list',
    'project.elements.attributes.attributeOrder': 'Order',
    'project.elements.attributes.code': 'Code',
    'project.elements.attributes.values': 'Possible values',
    'project.elements.attributes.showHideValues': 'Show/Hide possible values',
    'project.elements.attributes.add': 'Add attribute type',
    'project.elements.attributes.modify': 'Modify attribute type',
    'project.elements.attributes.attributeName': 'Attribute name',
    'project.elements.attributes.help': 'Help',
    'project.elements.attributes.translations': 'Internationalization',
    'project.elements.attributes.maxSize': 'Max size',
    'project.elements.attributes.mandatory': 'Mandatory',
    'project.elements.attributes.type': 'Attribute type',
    'project.elements.attributes.description': 'Description',
    'project.elements.attributes.name': 'Name',

    'project.elements.geometricLayer.layerGroup.name': 'Public name',
    'project.elements.geometricLayer.layerGroup.includesInsideLayerGroup': '"Other layers" subgroup?',
    'project.elements.geometricLayer.layerGroup.order': 'Order',
    'project.elements.geometricLayer.layerGroup.initiallyOpen': 'Initially open?',
    'project.elements.geometricLayer.layerGroup.placeBehindOtherLayers': 'Place after "Other layers"',
    'project.elements.geometricLayer.layerGroup.showLayers': 'Show/Hide associated layers',
    'project.elements.geometricLayer.layerGroup.add': 'Add layer group',
    'project.elements.geometricLayer.layerGroup.modify': 'Modify layer group',
    'project.elements.geometricLayer.layerGroup.deleteMessage': 'You\'re going to delete layer group "{element}", Do you want to continue?',
    'project.elements.geometricLayer.layerGroup.noElements': 'There are no layer groups',
    'project.elements.geometricLayer.layer.tableName': 'Internal database table name',
    'project.elements.geometricLayer.layer.internalName': 'Internal name',
    'project.elements.geometricLayer.layer.name': 'Public name',
    'project.elements.geometricLayer.layer.order': 'Order',
    'project.elements.geometricLayer.layer.initiallyVisible': 'Initially visible?',
    'project.elements.geometricLayer.layer.serverUrl': 'Server URL',
    'project.elements.geometricLayer.layer.serverUrl.internalLayer': 'Internal layer',
    'project.elements.geometricLayer.layer.type': 'Type',
    'project.elements.geometricLayer.layer.type.help': `<ul>
    <li><b>WMS:</b> a layer of type WMS will be created. The <i>Server URL</i> and <i>Internal Name</i> fields must be covered, and multiple layer names separated by commas can be used.</li>
    <li><b>WMS of the internal database:</b> a layer shall be created on the server and a layer of type WMS. The <i>Internal database table name</i> and <i>Internal name</i> fields must be covered.</li>
    <li><b>OpenStreetMap:</b> the <i>standard</i> OpenStreetMap layer will be created.</li>
    <li><b>XYZ:</b> a layer of type XYZ will be created. The <i>Server URL</i> field must be covered with a URL of type <i>http://xxxxx?x={x}&y={y}&z={z}</i>. For example: 
    <ul>
    <li>Google's <i>standard</i> layer <i>http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}</i></li></li>
    <li>Google's orthophoto layer <i>http://mt0.google.com/vt/lyrs=m&hl=gl&x={x}&y={y}&z={z}</i></li>
    </ul>
    </li>
    </ul>`,
    'project.elements.geometricLayer.layer.showAllLayersOnLegend': 'Show all layers on legend',
    'project.elements.geometricLayer.layer.showAllLayersOnLegend.help': 'If this option is checked, in the legend displayed on geographical viewer for this layer, only the legend of the first of the layers configured on "Internal name" will be displayed',
    'project.elements.geometricLayer.layer.legend': 'Legend',
    'project.elements.geometricLayer.layer.legend.title': 'Layer {layer} legend',
    'project.elements.geometricLayer.layer.legend.show': 'Show legend',
    'project.elements.geometricLayer.layer.internalComments': 'Internal observations',
    'project.elements.geometricLayer.layer.geometricLayerGroup': 'Layer group',
    'project.elements.geometricLayer.layer.version': 'Version',
    'project.elements.geometricLayer.layer.version.help': 'If no version is selected, version 1.3.0 will be used by default',
    'project.elements.geometricLayer.layer.add': 'Add layer',
    'project.elements.geometricLayer.layer.modify': 'Modify layer',
    'project.elements.geometricLayer.layer.noElements': 'There are no layers',
    'project.elements.geometricLayer.layer.deleteMessage': 'You\'re going to delete layer "{element}", Do you want to continue?',
    'project.elements.geometricLayer.layer.opacity': 'Opacity',
    'project.elements.geometricLayer.layer.opacity.defaultOpacity.help': 'Value must be between 0 and 100. If no value is specified, the layer will be displayed with an opacity of 100%',
    'project.elements.geometricLayer.layer.opacity.defaultOpacity.error': 'Field "Layer initial opacity" value must be between 0 and 100',
    'project.elements.geometricLayer.layer.opacity.defaultOpacity': 'Layer initial opacity',
    'project.elements.geometricLayer.layer.opacity.allowChangeOpacity.table': 'Show control on viewer',
    'project.elements.geometricLayer.layer.opacity.allowChangeOpacity.form': 'Show opacity change control on viewer',
    'project.elements.geometricLayer.layerGroup.order.help': 'The order must be unique within each of the three layer group blocks: before the group "Other layers", within the group "Other layers" and after the group "Other layers" ',
    'project.elements.geometricLayer.layer.supportsGetFeatureInfo': 'Supports GetFeatureInfo operation',
    'project.elements.geometricLayer.layer.informationFormatToUse': 'Format to GetFeatureInfo',
    'project.elements.geometricLayer.layer.useProxy': 'Use proxy in web viewer',
    'project.elements.geometricLayer.layer.useProxyAndroid': 'Use proxy in Android viewer',
    'project.elements.geometricLayer.layer.useProxy.help': 'This option should be used only when access to the layer fails without the use of the proxy. <b>The use of the proxy implies a higher load on the application server and an icrease in incoming and outgoing data traffic.</b>',
    'project.geometricLayer.layer.styleFile': 'Insert file',
    'project.geometricLayer.layer.styleFile.help': 'If a style file (.qml or .sld) is not uploaded, the server will automatically assign one',
    'project.geometricLayer.layer.styleFile.existent': 'Keep current style',
    'project.geometricLayer.layer.styleFile.new': 'Insert new style',
    'project.geometricLayer.layer.styleFile.new.help': 'Modifying the style will overwrite the current one',
    'project.geometricLayer.layer.styleFile.title': 'Display style',
    'project.geometricLayer.layer.shapeFile.title': 'Shape',
    'project.geometricLayer.layer.shapeFile.add': 'Insert file',
    'project.geometricLayer.layer.shapeFile.modify': 'File name',
    'project.geometricLayer.layer.shapeFile.help': 'A zip file containing the following must be uploaded: <ul><li>.shp file</li><li>.shx file</li><li>.dbf file</li><li>.prj file (optional): <b>if it does not contain it, the <i>Coordinate system</i> field must be covered</b></li><ul>',
    'project.geometricLayer.layer.shapeFile.existent': 'Keep current shape',
    'project.geometricLayer.layer.shapeFile.new': 'Insert new shape',
    'project.elements.geometricLayer.layer.shapeFile.coordinateSystem': 'Coordinate system',
    'project.elements.geometricLayer.layer.shapeFile.coordinateSystem.help': 'If left blank, the value specified in the .prj file shall be used. The format must be <b><i>EPSG:Coordinate system code</i></b> e.g. <b>EPSG:25829</b>',

    'project.elements.relations': 'Relations',
    'project.elements.relations.noRelations': 'No relations were found',
    'project.elements.relations.geometricElementType': 'Type of {singular}',
    'project.elements.relations.geometricElementId': 'Id {singular}',
    'project.elements.relations.geometricElementDescription': 'Description',
    'project.elements.relations.geometricElementAttributes': 'Attributes',
    "project.elements.relations.relate": 'Create relation',
    'project.elements.relations.add': 'Create relation',
    'project.elements.relations.modify': 'Modify relation',
    'project.elements.relations.delete.message': 'You\'re going to delete relation between the elements"{geometricElement1}" and "{geometricElement2}", Do you want to continue?',
    'project.elements.relations.author': 'Relation author',
    'project.elements.relations.id': 'Relation id',
    'project.elements.relations.geometricElement1': 'Id of {singular} 1',
    'project.elements.relations.geometricElement2': 'Id of {singular} 2',
    'project.elemens.relations.geometricElement.showDetails': 'View {singular} in detail',
    'project.elements.relations.isPublic': 'Relation is public?',
    'project.elements.relations.isReviewed': 'Relation is reviewed?',
    'project.elements.relations.comments': 'Relation comments',
    'project.elements.relations.anonymous.message': 'You must {link} to create new relation',

    'project.users.password': 'Password',
    'project.users.locale.pick': 'Select a language from the list',
    'project.users.confirmPassword': 'Confirm password',
    'project.users.confirmPassword.error': "Passwords don't match",
    'project.users.locale': 'Language',
    'project.users.locale.galician': 'Galician',
    'project.users.locale.spanish': 'Spanish',
    'project.users.locale.english': 'English',
    'project.users.currentPassword': 'Current password',
    'project.users.newPassword': 'New password',
    'project.users.confirmNewPassword': 'Confirm new password',
    'project.users.modal.modifyUser': '{user} {login}',
    'project.users.login': 'Username',
    'project.users.lock': 'Lock account',
    'project.users.user': 'User',
    'project.users.username': "Username",
    'project.users.locked': "Is locked?",
    'project.users.role': "Role",
    'project.users.changePassword': 'Change password',
    'project.users.createUser': 'Create user',
    'project.users.noDataIndication': 'There are no user accounts',

    'project.attachedFiles.add': 'Add attached file',
    'project.attachedFiles.add.file': 'File',
    'project.attachedFiles.modify': 'Modify attached file',
    'project.attachedFiles.description': 'Description',
    'project.attachedFiles.type': 'Type',
    'project.attachedFiles.internalFilename': 'Internal filename',
    'project.attachedFiles.originalFilename': 'Original filename',
    'project.attachedFiles.size': 'Size',
    'project.attachedFiles.fileFormat': 'File format',
    'project.attachedFiles.noElements': 'There are no attached files',
    'project.attachedFiles.errors.maxSize': 'The {filename} file you are trying to add exceeds maximum allowed size ({maxSize})',
    'project.attachedFiles.errors.formatNotPermitted': 'The {filename} file you are trying to add has an unsupported format',
    'project.attachedFiles.errors.mustBeImage': 'The file must be an image',
    'project.attachedFiles.error.cannotDownloadFileHeader': 'Error',
    'project.attachedFiles.error.cannotDownloadFileBody': 'File could not be downloaded'
}