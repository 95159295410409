import React from "react";
import * as appSelectors from '../../app/selectors'
import * as attachedFileSelectors from '../../attachedFile/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as configurationParameterSelectors from '../../configurationParameter/selectors';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Errors } from '../../common';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import jQuery from 'jquery';
import InternationalizationRender, { getInternationalization, PARAMETER_ELEMENT_TEXT_PLURAL, PARAMETER_ELEMENT_TEXT_SINGULAR } from "../../app/components/InternationalizationRender";
import PropTypes from 'prop-types'
import autocomplete from 'autocompleter';
import AddModifyGeneralAttachedFileFormFields, { validateGeneralAttachedFileField } from "../../attachedFile/components/AddModifyGeneralAttachedFileFormFields";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mapStateToProps = function (state) {
    return ({
        locale: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        allCodes: selectors.getAllCodes(state),
        listGeometricElements: selectors.getGeometricElementsByElementType(state),
        listAllAttributeType: selectors.getTotalAttributeType(state),
        allGeneralAttachedFiles: attachedFileSelectors.getTotalGeneralAttachedFiles(state),
        allFileFormat: attachedFileSelectors.getAllFileFormat(state),
        allConfigurationParameters: configurationParameterSelectors.getTotalConfigurationParameters(state),
        stylesForPoint: selectors.getStylesForPoint(state),
        stylesForPolygon: selectors.getStylesForPolygon(state),
        stylesForLine: selectors.getStylesForLine(state)
    });
}

class AddModifyGeometricElementType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formikErrors: null,
            hasGeometricElements: props.geometricElementToModify && props.listGeometricElements
                ? props.listGeometricElements.result.totalItems > 0 : false,
            hasAddedFiles: false
        };
    }

    componentDidMount() {
        if (this.props.stylesForPoint.length < 1 || this.props.stylesForPolygon.length < 1 || this.props.stylesForLine.length < 1) {
            this.props.dispatch(actions.findGeometricElementStyles());
        }
    }

    geometryTypeRender = () => {
        if (this.props.allCodes) {
            let allGeometryType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('GEOMETRY_TYPE') !== -1);
            return (
                allGeometryType.map(geometryType =>
                    <option value={geometryType.code} key={geometryType.code}>
                        {getInternationalization(this.props.locale, geometryType.code, allGeometryType, this.props.activeLanguages)}
                    </option>
                )
            );
        } else {
            return (null)
        }
    }

    legendTypeRender = () => {
        if (this.props.allCodes) {
            let allLegendType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('LEGEND_TYPE_GROUP_CODE') !== -1);
            return (
                allLegendType.length > 0 ?
                    <div className="form-group">
                        <label htmlFor="legendType" className="font-weight-bold required">
                            <FormattedMessage id="project.common.legendType" />
                        </label>
                        <Field as="select" id="legendType" name="legendType" className="form-control">
                            {allLegendType.map(legendType =>
                                <option value={legendType.code} key={legendType.code}>
                                    {getInternationalization(this.props.locale, legendType.code, allLegendType, this.props.activeLanguages)}
                                </option>)}
                        </Field>
                    </div>
                    :
                    null
            )
        }

        return null;
    }

    attributeTypeRender = () => {
        if (this.props.listAllAttributeType && this.props.allCodes) {
            return (
                this.props.listAllAttributeType.map(attributeType =>
                    <option value={JSON.stringify(attributeType.code)} key={attributeType.code.code}>
                        {getInternationalization(this.props.locale, attributeType.code.code,
                            this.props.allCodes, this.props.activeLanguages
                        )}
                    </option>
                )
            )
        } else {
            return (null)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (nextProps.geometricElementTypeToModify !== this.props.geometricElementTypeToModify) {
            return true;
        }

        if (nextProps.backendErrors !== this.props.backendErrors) {
            return true;
        }

        if (nextProps.setBackendErrors !== this.props.setBackendErrors) {
            return true;
        }

        if (nextProps.handleSubmit !== this.props.handleSubmit) {
            return true;
        }

        if (nextProps.hideModalWindow !== this.props.hideModalWindow) {
            return true;
        }

        if (nextState.hasGeometricElements !== this.state.hasGeometricElements) {
            return true;
        }

        if (nextProps.listAllAttributeType !== this.props.listAllAttributeType) {
            return true;
        }

        if (nextProps.listGeometricElements !== this.props.listGeometricElements) {
            return true;
        }
        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.listGeometricElements !== this.props.listGeometricElements) {
            this.setState({ hasGeometricElements: this.props.geometricElementTypeToModify ? this.props.listGeometricElements.result.totalItems > 0 : false, });
        }
        if (this.state.formikErrors || this.props.backendErrors) {
            let container = jQuery('.modal-body');
            if (this.props.backendErrors) {
                container.scrollTop(0);
            } else {
                if (this.state.formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }
        jQuery(function () {
            //show tooltip
            jQuery('.helpTooltip').tooltip({
                html: true,
                placement: 'right',
                trigger: 'click',
                // Prevent placement flip
                fallbackPlacement: ['right'],
                boundary: 'window',
                // Show tables and custom styles inside tooltip
                sanitize: false,
                template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                    '</div><div class="help-tooltip tooltip-inner"></div></div>'
            });
            //hide it when clicking anywhere else
            jQuery('body').on('click', function (e) {
                jQuery('.helpTooltip').each(function () {
                    //the 'is' for buttons that trigger popups
                    //the 'has' for icons within a button that triggers a popup
                    if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                        jQuery(this).tooltip('hide');
                    }
                });
            });
        });

        //Prevent clicking on help button activates input associated with label
        jQuery(document).on('tap click', 'label sup', function (event, data) {
            event.stopPropagation();
            event.preventDefault();
            return false;
        });
    }

    render() {
        let geometricElementTypeSchema = {
            geometricElementCode: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.elements.attributes.code" />,
                }} />),
            geometryType: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.elements.geometryType" />,
                }} />),
            defaultOpacity: Yup.number().default(null)
                .test("defaultOpacity", <FormattedMessage id="project.elements.opacity.defaultOpacity.error" />, function (value) {
                    return value >= 0 && value <= 100;
                }).nullable(true),
            legendType: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.common.legendType" />
                }} />),
            legendTypeValue: Yup.string().when("legendType", {
                is: (val) => val === "LEGEND_TYPE_URL",
                then: Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <FormattedMessage id="project.common.legendType.url" />
                    }} />),
                otherwise: Yup.string().notRequired()
            }),
            fileId: Yup.string().when(["legendType", "existentFile"], {
                is: (legendType, existentFile) => legendType === "LEGEND_TYPE_IMAGE" && existentFile,
                then: Yup.string().required(<FormattedMessage
                    id="project.common.legendType.file.error" />),
                otherwise: Yup.string().notRequired()
            }).nullable(),
            description: Yup.string().when(["legendType", "existentFile"], {
                is: (legendType, existentFile) => legendType === "LEGEND_TYPE_IMAGE" && !existentFile,
                then: Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <FormattedMessage id="project.attachedFiles.description" />,
                    }} />),
                otherwise: Yup.string().notRequired()
            }).nullable(),
            listAttributes: Yup.array().of(
                Yup.object().shape({
                    attributeType: Yup.string().required(<FormattedMessage
                        id="project.app.Body.field.required"
                        values={{
                            field: <FormattedMessage id="project.elements.attributes.type" />,
                        }} />),
                    attributeOrder: Yup.number()
                        .min(1, <FormattedMessage id="project.app.Body.field.positive"
                            values={{
                                field: <FormattedMessage id="project.elements.values.order" />
                            }}
                        />)
                        .required(<FormattedMessage
                            id="project.app.Body.field.required"
                            values={{
                                field: <FormattedMessage id="project.elements.values.order" />,
                            }} />)
                })
            )
        }

        this.props.activeLanguages.forEach(language => {
            geometricElementTypeSchema[language] = Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <InternationalizationRender value={language} />,
                }}
            />)
            geometricElementTypeSchema[language + "TooltipText"] = Yup.string().when("canJoin", {
                is: (canJoin) => canJoin,
                then: Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <InternationalizationRender value={language} />,
                    }}
                />),
                otherwise: Yup.string().notRequired()
            }).nullable()
            geometricElementTypeSchema[language + "TableText"] = Yup.string().when("canJoin", {
                is: (canJoin) => canJoin,
                then: Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <InternationalizationRender value={language} />,
                    }}
                />),
                otherwise: Yup.string().notRequired()
            }).nullable()
        })

        const Schema = Yup.object().shape(geometricElementTypeSchema);

        let initAutocomplete = (setFieldValue) => {
            let allGeneralAttachedFiles = this.props.allGeneralAttachedFiles;
            autocomplete({
                minLength: 3,
                input: document.getElementById('legendTypeValue'),
                fetch: function (text, update) {
                    setFieldValue("fileId", null)
                    text = text.toLowerCase();
                    var suggestions = allGeneralAttachedFiles.filter(val => {
                        return val.fileFormat.image && (val.description.toLowerCase().includes(text) || val.originalFilename.toLowerCase().includes(text))
                    });
                    update(suggestions)
                },
                render: function (item, currentValue) {
                    var div = document.createElement("div");
                    div.textContent = `${item.description} - ${item.originalFilename}`;
                    div.style.zIndex = 999999999999
                    var modalDiv = document.getElementById('modal');
                    item.label = div.textContent;
                    modalDiv.appendChild(div);

                    return div;
                },
                onSelect: function (item, input) {
                    jQuery('div.autocomplete').hide();
                    setFieldValue("legendTypeValue", item.label);
                    setFieldValue("fileId", item.id);
                },
                customize: function (input, inputRect, container, maxHeight) {
                    container.style.zIndex = 999999
                }
            });
        }

        let closeAndResetForm = (resetForm) => {
            this.props.hideModalWindow();
            resetForm({
                geometryType: "",
                pointStyleName: "",
                lineStyleName: "",
                polygonStyleName: "",
                geometricElementCode: "",
                geometricElementGl: "",
                geometricElementEs: "",
                geometricElementEn: "",
                legendType: "",
                legendTypeValue: "",
                existentFile: true,
                fileId: null,
                listAttributes: []
            });
        }

        let allowChangeOpacityConfigurationParameter = false;
        if (this.props.allConfigurationParameters) {
            allowChangeOpacityConfigurationParameter = Object.values(this.props.allConfigurationParameters).filter(parameter =>
                parameter.code.indexOf("ALLOW_CONFIGURE_LAYERS_OPACITY") !== -1);
            if (allowChangeOpacityConfigurationParameter.length > 0) {
                allowChangeOpacityConfigurationParameter = allowChangeOpacityConfigurationParameter[0].value;
                allowChangeOpacityConfigurationParameter = (allowChangeOpacityConfigurationParameter === 'true');
            } else {
                allowChangeOpacityConfigurationParameter = true;
            }
        }

        let initialValues = {
            geometryType: "",
            pointStyleName: "",
            lineStyleName: "",
            polygonStyleName: "",
            geometricElementCode: "",
            geometricElementGl: "",
            geometricElementEs: "",
            geometricElementEn: "",
            legendType: "LEGEND_TYPE_NONE",
            legendTypeValue: "",
            existentFile: false,
            fileId: null,
            listAttributes: [],
            description: "",
            file: {
                filename: "",
                size: "",
                type: "",
                base64: ""
            },
            defaultOpacity: null,
            allowChangeOpacity: false,
            canJoin: false
        }

        this.props.activeLanguages.forEach(actLan => {
            initialValues[actLan] = "";
            initialValues[actLan + "TooltipText"] = ""
            initialValues[actLan + "TableText"] = ""
        })

        if (this.props.geometricElementTypeToModify) {
            initialValues = {
                geometryType: this.props.geometricElementTypeToModify.geometryType,
                pointStyleName: this.props.geometricElementTypeToModify.pointStyleName,
                lineStyleName: this.props.geometricElementTypeToModify.lineStyleName,
                polygonStyleName: this.props.geometricElementTypeToModify.polygonStyleName,
                geometricElementCode: this.props.geometricElementTypeToModify.code,
                legendType: this.props.geometricElementTypeToModify.legendType,
                legendTypeValue: this.props.geometricElementTypeToModify.legendTypeValue ? this.props.geometricElementTypeToModify.legendTypeValue : "",
                existentFile: false,
                fileId: this.props.geometricElementTypeToModify.legendTypeValue,
                listAttributes: [],
                defaultOpacity: this.props.geometricElementTypeToModify.defaultOpacity ? this.props.geometricElementTypeToModify.defaultOpacity * 100 : null,
                allowChangeOpacity: this.props.geometricElementTypeToModify.allowChangeOpacity,
                canJoin: this.props.geometricElementTypeToModify.canJoin
            }
            this.props.activeLanguages.forEach(actLan => {
                let splittedLanguage = actLan.split("-")[0].toLowerCase();
                initialValues[actLan] = getInternationalization(
                    splittedLanguage,
                    this.props.geometricElementTypeToModify.code,
                    this.props.allCodes
                ) || ""
                initialValues[actLan + "TooltipText"] = getInternationalization(
                    splittedLanguage,
                    this.props.geometricElementTypeToModify.joinTooltipText,
                    this.props.allCodes
                ) || ""
                initialValues[actLan + "TableText"] = getInternationalization(
                    splittedLanguage,
                    this.props.geometricElementTypeToModify.joinTableText,
                    this.props.allCodes
                ) || ""
            });
            this.props.geometricElementTypeToModify.listAttributeType.forEach(attributeType => {
                initialValues.listAttributes.push({
                    attributeType: JSON.stringify({
                        id: attributeType.attributeType.code.id,
                        code: attributeType.attributeType.code.code,
                        codeGroup: {
                            id: attributeType.attributeType.code.codeGroup.id,
                            groupCode: attributeType.attributeType.code.codeGroup.groupCode
                        }
                    }),
                    attributeOrder: attributeType.attributeOrder,
                    mandatory: attributeType.mandatory,
                    disabled: this.props.hasGeometricElements
                })
            })
            if (initialValues.legendType === "LEGEND_TYPE_IMAGE") {
                initialValues.existentFile = true;
                let attachedFile = this.props.allGeneralAttachedFiles.filter(
                    // eslint-disable-next-line
                    generalAttachedFile => generalAttachedFile.id == this.props.geometricElementTypeToModify.legendTypeValue
                );
                if (attachedFile.length) {
                    attachedFile = attachedFile[0];
                    initialValues.legendTypeValue = `${attachedFile.description} - ${attachedFile.originalFilename}`;
                }
            }
        }

        if (this.props.listAllAttributeType) {
            return (
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={Schema}
                    validate={(values) => validateGeneralAttachedFileField(values.legendType === "LEGEND_TYPE_IMAGE" && !values.existentFile, true, this.props.allFileFormat, values)}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        this.props.setBackendErrors(null);
                        let elementToInsert = {
                            "id": -1,
                            "geometryType": values.geometryType,
                            "pointStyleName": values.geometryType.includes('POINT') || values.geometryType.includes('ANY') ? values.pointStyleName : null,
                            "lineStyleName": values.geometryType.includes('LINE') || values.geometryType.includes('ANY') ? values.lineStyleName : null,
                            "polygonStyleName": values.geometryType.includes('POLYGON') || values.geometryType.includes('ANY') ? values.polygonStyleName : null,
                            "legendType": values.legendType,
                            "legendTypeValue": !["LEGEND_TYPE_NONE", "LEGEND_TYPE_GET_LEGEND_GRAPHIC"].includes(values.legendType) ?
                                values.legendType === "LEGEND_TYPE_IMAGE" ? values.fileId : values.legendTypeValue
                                : null,
                            "defaultOpacity": values.defaultOpacity != null && values.defaultOpacity !== "" ? values.defaultOpacity / 100 : null,
                            "allowChangeOpacity": values.allowChangeOpacity,
                            "codeDto": {
                                "id": -1,
                                "code": values.geometricElementCode,
                                "codeGroup": {
                                    "id": -1,
                                    "codeGroup": "GEOMETRIC_ELEMENT_NAME"
                                },
                                "listInternationalizationDto": []
                            },
                            "canJoin": values.canJoin,
                            "joinTooltipText": values.canJoin ? {
                                "id": -1,
                                "code": null,
                                "codeGroup": null,
                                "listInternationalizationDto": []
                            } : null,
                            "joinTableText": values.canJoin ? {
                                "code": null,
                                "codeGroup": null,
                                "listInternationalizationDto": []
                            } : null
                        };

                        this.props.activeLanguages.forEach(actLan => {
                            let splittedLanguage = actLan.split("-")[0].toLowerCase();
                            elementToInsert.codeDto.listInternationalizationDto.push({
                                "codeDto": {
                                    "id": -1,
                                    "code": values.geometricElementCode,
                                    "codeGroup": {
                                        "id": -1,
                                        "groupCode": values.geometricElementCode
                                    }
                                },
                                "language": splittedLanguage,
                                "description": values[actLan]
                            });
                            if (values.canJoin) {
                                elementToInsert.joinTooltipText.listInternationalizationDto.push({
                                    "codeDto": {
                                        "id": -1,
                                        "code": null,
                                        "codeGroup": null
                                    },
                                    "language": splittedLanguage,
                                    "description": values[actLan + "TooltipText"]
                                })
                                elementToInsert.joinTableText.listInternationalizationDto.push({
                                    "codeDto": {
                                        "id": -1,
                                        "code": null,
                                        "codeGroup": null
                                    },
                                    "language": splittedLanguage,
                                    "description": values[actLan + "TableText"]
                                })
                            }
                        })

                        let listAttributes = [];
                        values.listAttributes.forEach(attribute => {
                            listAttributes.push({
                                "attributeOrder": attribute.attributeOrder,
                                "mandatory": attribute.mandatory,
                                "codeAttributeType": JSON.parse(attribute.attributeType)
                            })
                        })

                        elementToInsert["listAttributeType"] = listAttributes;
                        if (values.legendType === "LEGEND_TYPE_IMAGE" && !values.existentFile) {
                            elementToInsert.legendFile = {
                                description: values.description,
                                file: values.file
                            };
                        }
                        if (this.props.geometricElementTypeToModify) {
                            this.props.dispatch(actions.modifyGeometricElementType(this.props.geometricElementTypeToModify.id, elementToInsert, () => {
                                this.props.dispatch(actions.findAllCodes(
                                    () => {
                                        this.props.handleSubmit();
                                        resetForm();
                                    }
                                ));
                            },
                                errors => {
                                    this.props.setBackendErrors(errors);
                                }));
                        } else {
                            this.props.dispatch(actions.addGeometricElementType(elementToInsert, () => {
                                this.props.dispatch(actions.findAllCodes(
                                    () => {
                                        this.props.handleSubmit();
                                        resetForm();
                                    }
                                ));
                            },
                                errors => {
                                    this.props.setBackendErrors(errors);
                                }));
                        }
                        setSubmitting(false);
                    }}
                >
                    {({ values, resetForm, errors, setFieldValue, setFieldError }) =>
                        <Modal id="modal" show={this.props.modalShow} onHide={() => closeAndResetForm(resetForm)} scrollable={true} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {this.props.geometricElementTypeToModify ?
                                        <FormattedMessage id="project.elements.type.modify" values={{
                                            singular: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_SINGULAR} />
                                        }} />
                                        :
                                        <FormattedMessage id="project.elements.type.add" values={{
                                            singular: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_SINGULAR} />
                                        }} />
                                    }
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form className="needs-validation novalidate" id="addGeometricElementTypeForm">
                                    {errors ? this.setState({ formikErrors: errors }) : ""}
                                    <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />
                                    {document.getElementById('legendTypeValue') ? initAutocomplete(setFieldValue) : ""}

                                    <div className="form-group">
                                        <label htmlFor="geometricElementCode" className="font-weight-bold required">
                                            <FormattedMessage id="project.elements.attributes.code" />
                                        </label>
                                        <Field id="geometricElementCode" name="geometricElementCode" className="form-control"
                                            disabled={this.props.geometricElementTypeToModify ? true : false}
                                        />
                                    </div>
                                    <ErrorMessage name="geometricElementCode" render={(msg) =>
                                        <div id="geometricElementCodeError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                            hidden={typeof errors.geometricElementCode === "undefined"}
                                        >
                                            <button type="button" className="close" aria-label="Close"
                                                onClick={() => setFieldError("geometricElementCode", undefined)}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {msg}
                                        </div>
                                    }
                                    />

                                    <div className="card">
                                        {this.props.geometricElementTypeToModify ?
                                            <h5 className="card-header"><FormattedMessage id="project.elements.type.modify.name" /></h5>
                                            :
                                            <h5 className="card-header"><FormattedMessage id="project.elements.type.add.name" /></h5>
                                        }

                                        <div className="card-body">
                                            <div className="form-group">
                                                {this.props.activeLanguages.map(actLan => {
                                                    return (
                                                        <>
                                                            <div className="input-group mb-3 no-gutters">
                                                                <div className="input-group-prepend col-2">
                                                                    <span className="input-group-text col-12 required">
                                                                        <InternationalizationRender value={actLan} />
                                                                    </span>
                                                                </div>
                                                                <Field id={actLan} name={actLan} as="input" className="form-control" />
                                                            </div>
                                                            <ErrorMessage name={actLan} render={(msg) =>
                                                                <div id={`${actLan}Error`} className="alert alert-danger alert-dismissible fade show"
                                                                    role="alert" hidden={typeof errors[actLan] === "undefined"}
                                                                >
                                                                    <button type="button" className="close" aria-label="Close"
                                                                        onClick={() => setFieldError(actLan, undefined)}
                                                                    >
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                    {msg}
                                                                </div>
                                                            }
                                                            />
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`input-group ${values.canJoin ? 'mt-3' : 'my-3'}`}>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field as="input" type="checkbox" id="canJoin" name="canJoin" />
                                            </div>
                                            <label htmlFor="canJoin" className="btn input-group-text">
                                                <FormattedMessage id="project.elements.type.join.canJoin"
                                                    values={{
                                                        plural: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_PLURAL} />
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    {values.canJoin &&
                                        <div class="card card-body mb-3">
                                            <div className="card">
                                                <h5 className="card-header"><FormattedMessage id="project.elements.type.join.tooltipText" /></h5>

                                                <div className="card-body">
                                                    <div className="form-group">
                                                        {this.props.activeLanguages.map(actLan => {
                                                            let fieldName = `${actLan}TooltipText`;
                                                            return (
                                                                <>
                                                                    <div className="input-group mb-3 no-gutters">
                                                                        <div className="input-group-prepend col-2">
                                                                            <span className="input-group-text col-12 required">
                                                                                <InternationalizationRender value={actLan} />
                                                                            </span>
                                                                        </div>
                                                                        <Field id={fieldName} name={fieldName} as="input" className="form-control" />
                                                                    </div>
                                                                    <ErrorMessage name={fieldName} render={(msg) =>
                                                                        <div id={`${fieldName}TooltipTextError`} className="alert alert-danger alert-dismissible fade show"
                                                                            role="alert" hidden={typeof errors[fieldName] === "undefined"}
                                                                        >
                                                                            <button type="button" className="close" aria-label="Close"
                                                                                onClick={() => setFieldError(fieldName, undefined)}
                                                                            >
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                            {msg}
                                                                        </div>
                                                                    }
                                                                    />
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-3">
                                                <h5 className="card-header"><FormattedMessage id="project.elements.type.join.tableText" /></h5>

                                                <div className="card-body">
                                                    <div className="form-group">
                                                        {this.props.activeLanguages.map(actLan => {
                                                            let fieldName = `${actLan}TableText`;
                                                            return (
                                                                <>
                                                                    <div className="input-group mb-3 no-gutters">
                                                                        <div className="input-group-prepend col-2">
                                                                            <span className="input-group-text col-12 required">
                                                                                <InternationalizationRender value={actLan} />
                                                                            </span>
                                                                        </div>
                                                                        <Field id={fieldName} name={fieldName} as="input" className="form-control" />
                                                                    </div>
                                                                    <ErrorMessage name={fieldName} render={(msg) =>
                                                                        <div id={`${fieldName}TooltipTextError`} className="alert alert-danger alert-dismissible fade show"
                                                                            role="alert" hidden={typeof errors[fieldName] === "undefined"}
                                                                        >
                                                                            <button type="button" className="close" aria-label="Close"
                                                                                onClick={() => setFieldError(fieldName, undefined)}
                                                                            >
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                            {msg}
                                                                        </div>
                                                                    }
                                                                    />
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="form-group">
                                        <label htmlFor="geometryType" className="font-weight-bold required">
                                            <FormattedMessage id="project.elements.geometryType" />
                                        </label>
                                        <Field as="select" id="geometryType" name="geometryType" className="form-control"
                                            disabled={this.props.hasGeometricElements}
                                        >
                                            <FormattedMessage id="project.common.selectOneOption">
                                                {(msg) => <option value="">{msg}</option>}
                                            </FormattedMessage>
                                            {this.geometryTypeRender()}
                                        </Field>
                                    </div>
                                    <ErrorMessage name="geometryType" render={(msg) =>
                                        <div id="geometryTypeError" className="alert alert-danger alert-dismissible fade show"
                                            role="alert" hidden={typeof errors.geometryType === "undefined"}
                                        >
                                            <button type="button" className="close" aria-label="Close"
                                                onClick={() => setFieldError("geometryType", undefined)}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {msg}
                                        </div>
                                    }
                                    />
                                    {values.geometryType === "ANY_GEOMETRY" || values.geometryType.includes("POINT") ?
                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="pointStyleName">
                                                <FormattedMessage id="project.elements.pointStyleName" />
                                            </label>
                                            <Field as="select" id="pointStyleName" name="pointStyleName" className="form-control">
                                                <FormattedMessage id="project.common.selectOneOption">
                                                    {(msg) =>
                                                        <option value={""}>{msg}</option>
                                                    }
                                                </FormattedMessage>
                                                {this.props.stylesForPoint.map(style =>
                                                    <option value={style} key={style}>
                                                        {style}
                                                    </option>)}
                                            </Field>
                                        </div>
                                        :
                                        ""
                                    }
                                    {values.geometryType === "ANY_GEOMETRY" || values.geometryType.includes("LINE") ?
                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="lineStyleName">
                                                <FormattedMessage id="project.elements.lineStyleName" />
                                            </label>
                                            <Field as="select" id="lineStyleName" name="lineStyleName" className="form-control">
                                                <FormattedMessage id="project.common.selectOneOption">
                                                    {(msg) =>
                                                        <option value={""}>{msg}</option>
                                                    }
                                                </FormattedMessage>
                                                {this.props.stylesForLine.map(style =>
                                                    <option value={style} key={style}>
                                                        {style}
                                                    </option>)}
                                            </Field>
                                        </div>
                                        :
                                        ""
                                    }

                                    {values.geometryType === "ANY_GEOMETRY" || values.geometryType.includes("POLYGON") ?
                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="polygonStyleName">
                                                <FormattedMessage id="project.elements.polygonStyleName" />
                                            </label>
                                            <Field as="select" id="polygonStyleName" name="polygonStyleName" className="form-control">
                                                <FormattedMessage id="project.common.selectOneOption">
                                                    {(msg) =>
                                                        <option value={""}>{msg}</option>
                                                    }
                                                </FormattedMessage>
                                                {this.props.stylesForPolygon.map(style =>
                                                    <option value={style} key={style}>
                                                        {style}
                                                    </option>)}
                                            </Field>
                                        </div>
                                        :
                                        ""
                                    }

                                    <div className="form-group">
                                        <label className="font-weight-bold" htmlFor="defaultOpacity">
                                            <FormattedMessage id="project.elements.opacity.defaultOpacity" />
                                            &nbsp;
                                            <sup id="defaultOpacityHelp"
                                                className="helpTooltip btn-link"
                                                style={{ cursor: "pointer" }}
                                                aria-hidden={true}
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                data-original-title={
                                                    this.props.intl.formatMessage({
                                                        id: "project.elements.opacity.defaultOpacity.help"
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </sup>
                                        </label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">%</div>
                                            </div>
                                            <Field as="input" type="number" id="defaultOpacity" name="defaultOpacity" className="form-control" />
                                        </div>
                                    </div>
                                    <ErrorMessage name="defaultOpacity" render={(msg) =>
                                        <div id="defaultOpacityError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                            hidden={typeof errors.defaultOpacity === "undefined"}
                                        >
                                            <button type="button" className="close" aria-label="Close"
                                                onClick={() => setFieldError("defaultOpacity", undefined)}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {msg}
                                        </div>
                                    }
                                    />

                                    {allowChangeOpacityConfigurationParameter && <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field as="input" type="checkbox" id="allowChangeOpacity" name="allowChangeOpacity" />
                                            </div>
                                        </div>
                                        <label htmlFor="allowChangeOpacity" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.opacity.allowChangeOpacity.form" />
                                        </label>
                                    </div>}

                                    {this.legendTypeRender()}

                                    {values.legendType === "LEGEND_TYPE_URL" ?
                                        <>
                                            <div className="form-group">
                                                <label className="font-weight-bold required" htmlFor="legendTypeValue">
                                                    <FormattedMessage id="project.common.legendType.url" />
                                                </label>
                                                <Field as="input" id="legendTypeValue" name="legendTypeValue" className="form-control" />
                                            </div>
                                            <ErrorMessage name="legendTypeValue" render={(msg) =>
                                                <div id="legendTypeValueError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                    hidden={typeof errors.legendTypeValue === "undefined"}
                                                >
                                                    <button type="button" className="close" aria-label="Close"
                                                        onClick={() => setFieldError("legendTypeValue", undefined)}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    {msg}
                                                </div>
                                            }
                                            />
                                        </>
                                        :
                                        ""
                                    }

                                    {values.legendType === 'LEGEND_TYPE_IMAGE' ?
                                        <>
                                            <div className="custom-control custom-radio custom-control-inline mb-2">
                                                <Field type="radio"
                                                    id="newImageRadio"
                                                    name="existentFile"
                                                    className="custom-control-input"
                                                    value={false}
                                                    checked={!values.existentFile}
                                                    onChange={() => setFieldValue("existentFile", false)}
                                                />
                                                <label className="custom-control-label" htmlFor="newImageRadio">
                                                    <FormattedMessage id="project.common.legendType.file.unexistent" />
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <Field type="radio"
                                                    id="existentImageRadio"
                                                    name="existentFile"
                                                    className="custom-control-input"
                                                    value={true}
                                                    checked={values.existentFile}
                                                    onChange={() => setFieldValue("existentFile", true)}
                                                />
                                                <label className="custom-control-label" htmlFor="existentImageRadio">
                                                    <FormattedMessage id="project.common.legendType.file.existent" />
                                                </label>
                                            </div>
                                        </>
                                        : ""
                                    }

                                    {values.legendType === 'LEGEND_TYPE_IMAGE' && values.existentFile ?
                                        <>
                                            <div className="form-group">
                                                <label className="font-weight-bold required" htmlFor="legendTypeValue">
                                                    <FormattedMessage id="project.common.legendType.file" />
                                                </label>
                                                <Field as="input" id="legendTypeValue" name="legendTypeValue" className="form-control" />
                                            </div>
                                            <ErrorMessage name="fileId" render={(msg) =>
                                                <div id="fileIdError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                    hidden={typeof errors.fileId === "undefined"}
                                                >
                                                    <button type="button" className="close" aria-label="Close"
                                                        onClick={() => setFieldError("fileId", undefined)}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    {msg}
                                                </div>
                                            }
                                            />
                                        </>
                                        :
                                        values.legendType === 'LEGEND_TYPE_IMAGE' && !values.existentFile ?
                                            <div className="card mb-2">
                                                <div className="card-body">
                                                    <AddModifyGeneralAttachedFileFormFields
                                                        mustBeImage={true}
                                                        showTypeField={false}
                                                        setFieldValue={setFieldValue}
                                                        setFieldError={setFieldError}
                                                        errors={errors}
                                                        setHasAddedFiles={(hasAddedFiles) => this.setState({ hasAddedFiles })}
                                                        files={values.files}
                                                        resetValues={{}}
                                                        isLegendFile={true}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            ""
                                    }

                                    <div className="card">
                                        <h5 className="card-header">
                                            <FormattedMessage id="project.common.attributes" />
                                        </h5>
                                        <div className="card-body">
                                            <FieldArray id="listAttributes" name="listAttributes" className="form-control"
                                                render={arrayHelpers => (
                                                    <div>
                                                        {values.listAttributes.map((value, index) => (
                                                            <div key={index} className="form-group">

                                                                <label className="font-weight-bold required"
                                                                    htmlFor={`listAttributes[${index}].attributeType`}
                                                                >
                                                                    <FormattedMessage id="project.elements.attributes.type" />
                                                                </label>
                                                                <Field id={`listAttributes${index}AttributeType`}
                                                                    name={`listAttributes[${index}].attributeType`}
                                                                    as="select" className="form-control"
                                                                    disabled={value.disabled}
                                                                >

                                                                    <FormattedMessage id="project.common.selectOneOption">
                                                                        {(msg) =>
                                                                            <option value={""}>{msg}</option>
                                                                        }
                                                                    </FormattedMessage>
                                                                    {this.attributeTypeRender()}
                                                                </Field>
                                                                <ErrorMessage name={`listAttributes[${index}].attributeType`} render={(msg) =>
                                                                    <div id={`listAttributes${index}AttributeTypeError`}
                                                                        className="alert alert-danger alert-dismissible fade show" role="alert"
                                                                        hidden={typeof errors.listAttributes[index].attributeType === "undefined"}
                                                                    >
                                                                        <button type="button" className="close" aria-label="Close"
                                                                            onClick={() => setFieldError(`listAttributes[${index}].attributeType`, undefined)}
                                                                        >
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                        {msg}
                                                                    </div>
                                                                }
                                                                />
                                                                <br />
                                                                <label className="font-weight-bold required"
                                                                    htmlFor={`listAttributes[${index}].attributeOrder`}
                                                                >
                                                                    <FormattedMessage id="project.elements.values.order" />
                                                                </label>
                                                                <Field id={`listAttributes${index}AttributeOrder`}
                                                                    name={`listAttributes[${index}].attributeOrder`}
                                                                    as="input" type="number" className="form-control"
                                                                    disabled={value.disabled}
                                                                />
                                                                <ErrorMessage name={`listAttributes[${index}].attributeOrder`} render={(msg) =>
                                                                    <div id={`listAttributes${index}AttributeOrderError`}
                                                                        className="alert alert-danger alert-dismissible fade show" role="alert"
                                                                        hidden={typeof errors.listAttributes[index].attributeOrder === "undefined"}
                                                                    >
                                                                        <button type="button" className="close" aria-label="Close"
                                                                            onClick={() => setFieldError(`listAttributes[${index}].attributeOrder`, undefined)}
                                                                        >
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                        {msg}
                                                                    </div>
                                                                }
                                                                />
                                                                <br />
                                                                <div className="input-group mb-3">
                                                                    <div className="input-group-prepend">
                                                                        <div className="input-group-text">
                                                                            <Field id={`listAttributes${index}Mandatory`}
                                                                                name={`listAttributes[${index}].mandatory`}
                                                                                as="input" type="checkbox" disabled={value.disabled}
                                                                            />
                                                                        </div>
                                                                        <label htmlFor={`listAttributes${index}Mandatory`} className="btn input-group-text">
                                                                            <FormattedMessage id="project.elements.attributes.mandatory" />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <ErrorMessage name={`listAttributes[${index}].mandatory`} render={(msg) =>
                                                                    <div id={`listAttributes${index}MandatoryError`}
                                                                        className="alert alert-danger alert-dismissible fade show" role="alert"
                                                                        hidden={typeof errors.listAttributes[index].mandatory === "undefined"}
                                                                    >
                                                                        <button type="button" className="close" aria-label="Close"
                                                                            onClick={() => setFieldError(`listAttributes[${index}].mandatory`, undefined)}
                                                                        >
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                        {msg}
                                                                    </div>
                                                                }
                                                                />


                                                                <button className="btn btn-sm btn-secondary"
                                                                    type="button" id={"deleteAttribute" + index} onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}
                                                                    disabled={value.disabled}
                                                                >
                                                                    -
                                                                </button>
                                                            </div>
                                                        ))}
                                                        <button className="btn btn-secondary" type="button" id="addAttribute" onClick={() => {
                                                            arrayHelpers.push({
                                                                attributeType: "", attributeOrder: '', mandatory: '', disabled: false
                                                            })
                                                        }} disabled={this.props.hasGeometricElements}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <ErrorMessage name="maxSize" render={(msg) =>
                                            <div id="maxSizeError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                hidden={typeof errors.maxSize === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("maxSize", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <button id="createGeometricElementTypeSubmit" type="submit" className="btn btn-primary"
                                    form="addGeometricElementTypeForm"
                                    disabled={values.legendType === "LEGEND_TYPE_IMAGE" && !this.state.hasAddedFiles && !values.existentFile}
                                >
                                    <FormattedMessage id="project.app.Body.accept" />
                                </button>
                                <button id="createGeometricElementTypeCancel" type="button" className="btn btn-danger"
                                    onClick={() => {
                                        closeAndResetForm(resetForm);
                                    }}
                                >
                                    <FormattedMessage id="project.app.Body.cancel" />
                                </button>
                            </Modal.Footer>
                        </Modal>
                    }
                </Formik >
            );
        }

        return null;
    }
}

AddModifyGeometricElementType.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    geometricElementTypeToModify: PropTypes.object,
    backendErrors: PropTypes.object.isRequired,
    setBackendErrors: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps)(injectIntl(AddModifyGeometricElementType)));