import React from 'react';
import { connect } from 'react-redux';
import * as selectors from '../selectors';
import * as actions from '../actions';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { faEye, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jQuery from 'jquery';
import { DeleteDialog } from '../../common';
import { Modal } from 'react-bootstrap';
import backend from '../../../backend';
import ExternalImage from '../../common/components/ExternalImage';
import AddGeometricElementStyle from './AddGeometricElementStyle';
import InternationalizationRender, { PARAMETER_ELEMENT_TEXT_PLURAL } from '../../app/components/InternationalizationRender';

const mapStateToProps = function (state) {
    return ({
        allGeometricElementType: selectors.getTotalGeometricElementType(state),
        stylesForPoint: selectors.getStylesForPoint(state),
        stylesForPolygon: selectors.getStylesForPolygon(state),
        stylesForLine: selectors.getStylesForLine(state)
    });
}

class AllGeometricElementTypeStyles extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            backendErrors: null,
            layerType: null,
            styleName: null,
            addGeometricElementStyleModalShow: false,
            addGeometricElementStyleModalTitle: null,
            showGeometricElementStyleModalShow: false,
            deleteGeometricElementStyleModalShow: false
        }
    }

    canDeletePointStyle = (pointStyle) => {
        let notAssociatedGeometricElementType = this.props.allGeometricElementType.filter(geometricElementType =>
            geometricElementType.pointStyleName === pointStyle).length < 1;
        let notLastStyle = this.props.stylesForPoint.length > 1;
        return notLastStyle && notAssociatedGeometricElementType;
    }

    canDeleteLineStyle = (lineStyle) => {
        let notAssociatedGeometricElementType = this.props.allGeometricElementType.filter(geometricElementType => 
            geometricElementType.lineStyleName === lineStyle).length < 1;
        let notLastStyle = this.props.stylesForLine.length > 1;
        return notLastStyle && notAssociatedGeometricElementType
    }

    canDeletePolygonStyle = (polygonStyle) => {
        let notAssociatedGeometricElementType = this.props.allGeometricElementType.filter(geometricElementType => 
            geometricElementType.polygonStyleName === polygonStyle).length < 1;
        let notLastStyle = this.props.stylesForPolygon.length > 1;
        return notLastStyle && notAssociatedGeometricElementType;
    }

    setBackendErrors = (error) => {
        this.setState({ backendErrors: error })
    }

    componentDidMount() {
        let container = jQuery('#mainFooter');
        jQuery(container).scrollTop(0);
        if (this.props.stylesForPoint.length < 1 || this.props.stylesForPolygon.length < 1 || this.props.stylesForLine.length < 1) {
            this.props.dispatch(actions.findGeometricElementStyles());
        }
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
    }

    render() {

        jQuery(function () {
            jQuery('.actionButton').tooltip({
                trigger: "hover",
                title: function () {
                    return jQuery(this).attr('title');
                }
            });
        });

        jQuery(function () {
            jQuery('.actionButton').tooltip().click(function () {
                jQuery('.actionButton').tooltip("hide")
            });
        });

        if (this.props.allGeometricElementType) {
            return (
                <div className="card">
                    <br />
                    <div className="card-body">
                        <h3><FormattedMessage id="project.app.Header.admin.geometricElementStyles" values={{
                            plural: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_PLURAL}/> 
                        }} /></h3>
                    </div>
                    <div className="card mb-3">
                        <h5 className="card-header text-left">
                            <FormattedMessage id="project.elements.styles.point" />
                        </h5>
                        <div className="card-body container-fluid">
                            <div className="text-right">
                                <span id={"addGeometricElementPointStyle"}
                                    onClick={() => {
                                        this.setState({
                                            addGeometricElementStyleModalTitle: <FormattedMessage id="project.elements.styles.point.add" />,
                                            layerType: "POINT",
                                            addGeometricElementStyleModalShow: true
                                        })
                                    }}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    className="actionButton btn-link mx-1"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.elements.styles.point.add" })}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                            </div>

                            <div className="row row-cols-md-1 row-cols-md-2 row-cols-md-3 row-cols-md-4 row-cols-md-5 row-cols-md-6 card-block">
                                {this.props.stylesForPoint.map(style => {
                                    return (
                                        <div className="col-md mb-3 mx-3" key={style}>
                                            <div className="row">{style}</div>
                                            <div className="row">
                                                <div className="text-right mb-3">
                                                    <span id={"preview" + style}
                                                        onClick={() => {
                                                            this.setState({
                                                                layerType: "POINT",
                                                                styleName: style,
                                                                showGeometricElementStyleModalShow: true
                                                            })
                                                        }}
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                        className="actionButton btn-link mx-1"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title={this.props.intl.formatMessage({ id: "project.elements.styles.show" })}
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </span>
                                                    {this.canDeletePointStyle(style) &&
                                                        <span id={"delete" + style}
                                                            onClick={() => {
                                                                this.setState({
                                                                    layerType: "POINT",
                                                                    styleName: style,
                                                                    deleteGeometricElementStyleModalShow: true
                                                                })
                                                            }}
                                                            style={{
                                                                cursor: "pointer"
                                                            }}
                                                            className="actionButton btn-link mx-1"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <h5 className="card-header text-left">
                            <FormattedMessage id="project.elements.styles.line" />
                        </h5>
                        <div className="card-body container-fluid">
                            <div className="text-right">
                                <span id={"addGeometricElementLineStyle"}
                                    onClick={() => {
                                        this.setState({
                                            layerType: "LINE",
                                            addGeometricElementStyleModalTitle: <FormattedMessage id="project.elements.styles.line.add" />,
                                            addGeometricElementStyleModalShow: true
                                        })
                                    }}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    className="actionButton btn-link mx-1"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.elements.styles.line.add" })}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                            </div>

                            <div className="row row-cols-md-1 row-cols-md-2 row-cols-md-3 row-cols-md-4 row-cols-md-5 row-cols-md-6 card-block">
                                {this.props.stylesForLine.map(style => {
                                    return (
                                        <div className="col-md mb-3 mx-3" key={style}>
                                            <div className="row">{style}</div>
                                            <div className="row">
                                                <div className="text-right mb-3">
                                                    <span id={"preview" + style}
                                                        onClick={() => {
                                                            this.setState({
                                                                layerType: "LINE",
                                                                styleName: style,
                                                                showGeometricElementStyleModalShow: true
                                                            })
                                                        }}
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                        className="actionButton btn-link mx-1"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title={this.props.intl.formatMessage({ id: "project.elements.styles.show" })}
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </span>
                                                    {this.canDeleteLineStyle(style) &&
                                                        <span id={"delete" + style}
                                                            onClick={() => {
                                                                this.setState({
                                                                    layerType: "LINE",
                                                                    styleName: style,
                                                                    deleteGeometricElementStyleModalShow: true
                                                                });
                                                            }}
                                                            style={{
                                                                cursor: "pointer"
                                                            }}
                                                            className="actionButton btn-link mx-1"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <h5 className="card-header text-left">
                            <FormattedMessage id="project.elements.styles.polygon" />
                        </h5>
                        <div className="card-body container-fluid">
                            <div className="text-right">
                                <span id={"addGeometricElementPolygonStyle"}
                                    onClick={() => {
                                        this.setState({
                                            layerType: "POLYGON",
                                            addGeometricElementStyleModalTitle: <FormattedMessage id="project.elements.styles.polygon.add" />,
                                            addGeometricElementStyleModalShow: true
                                        });
                                    }}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    className="actionButton btn-link mx-1"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.elements.styles.polygon.add" })}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                            </div>

                            <div className="row row-cols-md-1 row-cols-md-2 row-cols-md-3 row-cols-md-4 row-cols-md-5 row-cols-md-6 card-block">
                                {this.props.stylesForPolygon.map(style => {
                                    return (
                                        <div className="col-md mb-3 mx-3" key={style}>
                                            <div className="row">{style}</div>
                                            <div className="row">
                                                <div className="text-right mb-3">
                                                    <span id={"preview" + style}
                                                        onClick={() => {
                                                            this.setState({
                                                                layerType: "POLYGON",
                                                                styleName: style,
                                                                showGeometricElementStyleModalShow: true
                                                            });
                                                        }}
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                        className="actionButton btn-link mx-1"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title={this.props.intl.formatMessage({ id: "project.elements.styles.show" })}
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </span>
                                                    {this.canDeletePolygonStyle(style) &&
                                                        <span id={"delete" + style}
                                                            onClick={() => {
                                                                this.setState({
                                                                    layerType: "POLYGON",
                                                                    styleName: style,
                                                                    deleteGeometricElementStyleModalShow: true
                                                                });
                                                            }}
                                                            style={{
                                                                cursor: "pointer"
                                                            }}
                                                            className="actionButton btn-link mx-1"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    {/* Delete geometric element style dialog */}
                    <DeleteDialog
                        modalShow={this.state.deleteGeometricElementStyleModalShow}
                        title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                        details={this.props.intl.formatMessage(
                            { id: 'project.elements.styles.delete.message' },
                            { style: <b>{this.state.styleName}</b> })
                        }
                        backendErrors={this.state.backendErrors}
                        hideModalWindow={() => { this.setState({ deleteGeometricElementStyleModalShow: false, backendErrors: null }) }}
                        handleSubmit={() => {
                            this.props.dispatch(actions.deleteGeometricElementStyle(this.state.layerType, this.state.styleName,
                                () => {
                                    this.setState({
                                        deleteGeometricElementStyleModalShow: false
                                    });
                                },
                                (error) => this.setBackendErrors(error)))
                        }}
                        setBackendErrors={this.setBackendErrors}
                    />

                    <Modal
                        show={this.state.showGeometricElementStyleModalShow}
                        size="xs" onHide={() => this.setState({ showGeometricElementStyleModalShow: false })}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {`${this.props.intl.formatMessage({ id: "project.elements.styles.show" })} ${this.state.styleName}`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ExternalImage
                                className="d-block"
                                src={backend.elementService.geometricElementStylesPreviewURL(this.state.layerType, this.state.styleName)}
                                alt=""
                            />
                        </Modal.Body>
                    </Modal>

                    {/* Add geometric element style */}
                    <AddGeometricElementStyle
                        title={this.state.addGeometricElementStyleModalTitle}
                        modalShow={this.state.addGeometricElementStyleModalShow}
                        hideModalWindow={() => this.setState({ addGeometricElementStyleModalShow: false, backendErrors: null })}
                        layerType={this.state.layerType}
                        backendErrors={this.state.backendErrors}
                        setBackendErrors={(backendErrors) => this.setState({ backendErrors })}
                    />

                </div>
            )
        }

        return null;
    }
}

export default withRouter(connect(mapStateToProps)(injectIntl(AllGeometricElementTypeStyles)))