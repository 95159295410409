import React from "react";
import * as appSelectors from '../../app/selectors'
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as attachedFileSelectors from '../../attachedFile/selectors';
import * as configurationParameterSelectors from '../../configurationParameter/selectors';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Errors } from '../../common';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import jQuery from 'jquery';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import autocomplete from 'autocompleter';
import { AddModifyGeneralAttachedFileFormFields } from "../../attachedFile";
import { validateGeneralAttachedFileField } from "../../attachedFile/components/AddModifyGeneralAttachedFileFormFields";
import * as FilePond from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { initTooltipHelp } from "../../common/util";
import { internationalizatedTextSplit } from "../../configurationParameter/components/UpdateConfigurationParameter";

const mapStateToProps = function (state) {
    return ({
        locale: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        allCodes: selectors.getAllCodes(state),
        listAllGeometricLayerGroup: selectors.getTotalGeometricLayerGroup(state),
        allGeneralAttachedFiles: attachedFileSelectors.getTotalGeneralAttachedFiles(state),
        allFileFormat: attachedFileSelectors.getAllFileFormat(state),
        allConfigurationParameters: configurationParameterSelectors.getTotalConfigurationParameters(state),
        layerSource: selectors.getLayerSource(state)
    });
}

const WMS_CODE = "WMS";
const WMS_DATABASE_CODE = "WMS_DATABASE";
const WMS_SHAPE_CODE = "WMS_SHAPE";
const OSM_CODE = "OSM";

class AddModifyGeometricLayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formikErrors: null,
            formikValueType: "",
            formikShowAllLayersOnLegend: "",
            hasAddedFiles: false
        };
    }

    disableSubmitButton = (values) => {
        if (values.type === WMS_DATABASE_CODE || values.type === WMS_SHAPE_CODE) {

            if (values.type === WMS_DATABASE_CODE) {
                if (values.legendType === "LEGEND_TYPE_IMAGE") {
                    if (this.props.geometricLayerToModify) {
                        if (!values.existentStyle && values.styleFile === null) {
                            return true;
                        }
                    }

                    return !this.state.hasAddedFiles && !values.existentFile;
                } else {
                    if (this.props.geometricLayerToModify) {
                        if (!values.existentStyle && values.styleFile === null) {
                            return true;
                        }
                    }

                    return false;
                }
            } else {
                if (values.legendType === "LEGEND_TYPE_IMAGE") {
                    if (!values.existentShape && values.shapeFile === null) {
                        return true;
                    }

                    if (this.props.geometricLayerToModify) {
                        if (!values.existentStyle && values.styleFile === null) {
                            return true;
                        }
                    }

                    return !this.state.hasAddedFiles && !values.existentFile;
                } else {
                    if (!values.existentShape && values.shapeFile === null) {
                        return true;
                    }

                    if (this.props.geometricLayerToModify) {
                        if (!values.existentStyle && values.styleFile === null) {
                            return true;
                        }
                    }

                    return false;
                }
            }
        } else {
            return values.legendType === "LEGEND_TYPE_IMAGE" && !this.state.hasAddedFiles && !values.existentFile;
        }
    }

    renderShapeFile = (setFieldValue, values) => {
        return (
            <div className="card">
                <h5 className="card-header"><FormattedMessage id="project.geometricLayer.layer.shapeFile.title" /></h5>
                <div className="card-body">
                    {this.props.geometricLayerToModify ?
                        <>
                            <div className="custom-control custom-radio custom-control-inline">
                                <Field type="radio"
                                    id="existentShapeRadio"
                                    name="existentShape"
                                    className="custom-control-input"
                                    value={true}
                                    checked={values.existentShape}
                                    onChange={() => {
                                        setFieldValue("shapeFile", null);
                                        setFieldValue("existentShape", true);
                                    }}
                                />
                                <label className="custom-control-label" htmlFor="existentShapeRadio">
                                    <FormattedMessage id="project.geometricLayer.layer.shapeFile.existent" />
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline mb-2">
                                <Field type="radio"
                                    id="newShapeRadio"
                                    name="existentShape"
                                    className="custom-control-input"
                                    value={false}
                                    checked={!values.existentShape}
                                    onChange={() => {
                                        initTooltipHelp();
                                        setFieldValue("existentShape", false);
                                    }}
                                />
                                <label className="custom-control-label" htmlFor="newShapeRadio">
                                    <FormattedMessage id="project.geometricLayer.layer.shapeFile.new" />
                                </label>
                            </div>
                        </> : ""
                    }
                    <div className="form-group">
                        <label className={`font-weight-bold ${!values.existentShape ? 'required' : ''}`} htmlFor="shapeFile">
                            <FormattedMessage id={values.existentShape ?
                                'project.geometricLayer.layer.shapeFile.modify' : 'project.geometricLayer.layer.shapeFile.add'}
                            />
                            {!values.existentShape &&
                                <>
                                    &nbsp;
                                    <sup id="shapeFileHelp"
                                        className="helpTooltip btn-link"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden={true}
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        data-original-title={
                                            this.props.intl.formatMessage({
                                                id: 'project.geometricLayer.layer.shapeFile.help'
                                            })
                                        }
                                    >
                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                    </sup>
                                </>
                            }
                        </label>
                        {!values.existentShape ?
                            <FilePond.FilePond
                                id="shapeFile"
                                instantUpload={false}
                                allowMultiple={false}
                                labelIdle={this.props.intl.formatMessage({ id: 'project.common.filepond.labelIdle' })}
                                labelTapToCancel={this.props.intl.formatMessage({ id: 'project.common.filepond.labelTapCancel' })}
                                labelFileLoading={this.props.intl.formatMessage({ id: 'project.common.filepond.labelLoading' })}
                                labelFileWaitingForSize={this.props.intl.formatMessage({ id: 'project.common.filepond.labelWaitForSize' })}
                                onremovefile={(error, file) => {
                                    setFieldValue("shapeFile", null);
                                }}
                                onaddfile={(error, file) => {
                                    setFieldValue("shapeFile", file.file)
                                }}
                            />
                            :
                            <span className="d-block" id="shapeFile">
                                {this.props.layerSource && this.props.layerSource.source}
                            </span>
                        }
                    </div>
                    {!values.existentShape &&
                        <div className="form-group">
                            <label htmlFor="coordinateSystem" className="font-weight-bold">
                                <FormattedMessage id="project.elements.geometricLayer.layer.shapeFile.coordinateSystem" />
                                &nbsp;
                                <sup id="coordinateSystemHelp"
                                    className="helpTooltip btn-link"
                                    style={{ cursor: "pointer" }}
                                    aria-hidden={true}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    data-original-title={
                                        this.props.intl.formatMessage({
                                            id: "project.elements.geometricLayer.layer.shapeFile.coordinateSystem.help"
                                        })
                                    }
                                >
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                </sup>
                            </label>
                            <Field id="coordinateSystem" name="coordinateSystem" className="form-control" />
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderStyleFile = (setFieldValue, values) => {
        return (
            <div className="card my-3">
                <h5 className="card-header"><FormattedMessage id="project.geometricLayer.layer.styleFile.title" /></h5>
                <div className="card-body">
                    {this.props.geometricLayerToModify ?
                        <>
                            <div className="custom-control custom-radio custom-control-inline">
                                <Field type="radio"
                                    id="existentStyleRadio"
                                    name="existentStyle"
                                    className="custom-control-input"
                                    value={true}
                                    checked={values.existentStyle}
                                    onChange={() => {
                                        setFieldValue("styleFile", null);
                                        setFieldValue("existentStyle", true);
                                    }}
                                />
                                <label className="custom-control-label" htmlFor="existentStyleRadio">
                                    <FormattedMessage id="project.geometricLayer.layer.styleFile.existent" />
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline mb-2">
                                <Field type="radio"
                                    id="newStyleRadio"
                                    name="existentStyle"
                                    className="custom-control-input"
                                    value={false}
                                    checked={!values.existentStyle}
                                    onChange={() => {
                                        initTooltipHelp();
                                        setFieldValue("existentStyle", false);
                                    }}
                                />
                                <label className="custom-control-label" htmlFor="newStyleRadio">
                                    <FormattedMessage id="project.geometricLayer.layer.styleFile.new" />
                                </label>
                            </div>
                        </> : ""
                    }
                    {!values.existentStyle &&
                        <div className="form-group">
                            <label className={`font-weight-bold ${this.props.geometricLayerToModify ? 'required' : ''}`} htmlFor="styleFile">
                                <FormattedMessage id="project.geometricLayer.layer.styleFile" />
                                &nbsp;
                                <sup id="styleFileHelp"
                                    className="helpTooltip btn-link"
                                    style={{ cursor: "pointer" }}
                                    aria-hidden={true}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    data-original-title={
                                        this.props.intl.formatMessage({
                                            id: this.props.geometricLayerToModify ?
                                                'project.geometricLayer.layer.styleFile.new.help'
                                                :
                                                'project.geometricLayer.layer.styleFile.help'
                                        })
                                    }
                                >
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                </sup>
                            </label>
                            <FilePond.FilePond
                                id="styleFile"
                                instantUpload={false}
                                allowMultiple={false}
                                labelIdle={this.props.intl.formatMessage({ id: 'project.common.filepond.labelIdle' })}
                                labelTapToCancel={this.props.intl.formatMessage({ id: 'project.common.filepond.labelTapCancel' })}
                                labelFileLoading={this.props.intl.formatMessage({ id: 'project.common.filepond.labelLoading' })}
                                labelFileWaitingForSize={this.props.intl.formatMessage({ id: 'project.common.filepond.labelWaitForSize' })}
                                onremovefile={(error, file) => {
                                    setFieldValue("styleFile", null);
                                }}
                                onaddfile={(error, file) => {
                                    setFieldValue("styleFile", file.file)
                                }}
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }

    geometricLayerTypeSelectorRender = () => {
        if (this.props.allCodes) {
            let geometricLayerTypeList = this.props.allCodes.filter(code => code.codeGroup.groupCode
                .indexOf('GEOMETRIC_LAYER_TYPE_GROUP_CODE') !== -1);
            return (
                geometricLayerTypeList.map(geometricLayerType =>
                    <option value={geometricLayerType.code} key={geometricLayerType.code}>
                        {getInternationalization(this.props.locale, geometricLayerType.code, geometricLayerTypeList, this.props.activeLanguages)}
                    </option>
                )
            );
        } else {
            return (null)
        }
    }

    geometricLayerGroupSelectorRender = () => {

        if (this.props.listAllGeometricLayerGroup && this.props.allCodes) {
            return (
                this.props.listAllGeometricLayerGroup.map(geometricLayerGroup =>
                    <option value={geometricLayerGroup.code.code} key={geometricLayerGroup.code.code}>
                        {getInternationalization(this.props.locale, geometricLayerGroup.code.code, this.props.allCodes, this.props.activeLanguages)}
                    </option>
                )
            )
        } else {
            return (null)
        }
    }

    legendTypeRender = () => {
        if (this.props.allCodes) {
            let allLegendType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('LEGEND_TYPE_GROUP_CODE') !== -1);
            return (
                allLegendType.length > 0 ?
                    <div className="form-group">
                        <label htmlFor="legendType" className="font-weight-bold required">
                            <FormattedMessage id="project.common.legendType" />
                        </label>
                        <Field as="select" id="legendType" name="legendType" className="form-control">
                            {allLegendType.map(legendType =>
                                <option value={legendType.code} key={legendType.code}>
                                    {getInternationalization(this.props.locale, legendType.code, allLegendType, this.props.activeLanguages)}
                                </option>)}
                        </Field>
                    </div>
                    :
                    null
            )
        }

        return null;
    }

    informationFormatToUseSelectorRender = () => {
        if (this.props.allCodes) {
            let informationFormatToUseSelectorList = this.props.allCodes.filter(code => code.codeGroup.groupCode
                .indexOf('OTHER_LAYERS_INFO_FORMAT_GROUP_CODE') !== -1);
            return (
                informationFormatToUseSelectorList.map(informationFormatToUse =>
                    <option value={informationFormatToUse.code} key={informationFormatToUse.code}>
                        {getInternationalization(this.props.locale, informationFormatToUse.code, informationFormatToUseSelectorList, this.props.activeLanguages)}
                    </option>
                )
            );
        } else {
            return (null)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (nextProps.geometricLayerToModify !== this.props.geometricLayerToModify) {
            return true;
        }

        if (nextProps.backendErrors !== this.props.backendErrors) {
            return true;
        }

        if (nextProps.setBackendErrors !== this.props.setBackendErrors) {
            return true;
        }

        if (nextProps.handleSubmit !== this.props.handleSubmit) {
            return true;
        }

        if (nextProps.hideModalWindow !== this.props.hideModalWindow) {
            return true;
        }

        if (nextState.formikValueType !== this.state.formikValueType) {
            return true;
        }

        if (nextState.formikShowAllLayersOnLegend !== this.state.formikShowAllLayersOnLegend) {
            return true;
        }

        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }

        if (nextState.layerSource !== this.state.layerSource) {
            return true;
        }

        return false;
    }

    componentDidUpdate() {
        if (this.state.formikErrors || this.props.backendErrors) {
            let container = jQuery('.modal-body');
            if (this.props.backendErrors) {
                container.scrollTop(0);
            } else {
                if (this.state.formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }

        initTooltipHelp()
    }

    render() {

        FilePond.registerPlugin(FilePondPluginFileEncode);

        let formFieldsValidation = {
            internalName: Yup.string().when('type', {
                is: WMS_CODE,
                then: Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <FormattedMessage id="project.elements.geometricLayer.layer.internalName" />,
                    }} />),
                otherwise: Yup.string().notRequired().nullable()
            }),
            geometricLayerGroup: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.elements.geometricLayer.layer.geometricLayerGroup" />,
                }} />),
            serverUrl: Yup.string().when(["type"], {
                is: (type) => type !== OSM_CODE && type !== WMS_DATABASE_CODE && type !== WMS_SHAPE_CODE,
                then: Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <FormattedMessage id="project.elements.geometricLayer.layer.serverUrl" />,
                    }} />),
                otherwise: Yup.string().notRequired().nullable()
            }),
            informationFormatToUse: Yup.string().when(['supportsGetFeatureInfo', 'type'], {
                is: (supportsGetFeatureInfo, type) => supportsGetFeatureInfo && (type === WMS_CODE || type === WMS_DATABASE_CODE || type === WMS_SHAPE_CODE),
                then: Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <FormattedMessage id="project.elements.geometricLayer.layer.informationFormatToUse" />,
                    }} />),
                otherwise: Yup.string().notRequired().nullable()
            }),
            type: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.elements.geometricLayer.layer.type" />,
                }} />),
            order: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.elements.geometricLayer.layer.order" />,
                }} />),
            defaultOpacity: Yup.number().default(null)
                .test("defaultOpacity", <FormattedMessage id="project.elements.geometricLayer.layer.opacity.defaultOpacity.error" />, function (value) {
                    return value >= 0 && value <= 100;
                }).nullable(true),
            legendType: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.common.legendType" />
                }} />),
            legendTypeValue: Yup.string().when("legendType", {
                is: (val) => val === "LEGEND_TYPE_URL",
                then: Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <FormattedMessage id="project.common.legendType.url" />
                    }} />),
                otherwise: Yup.string().notRequired()
            }),
            fileId: Yup.string().when(["legendType", "existentFile"], {
                is: (legendType, existentFile) => legendType === "LEGEND_TYPE_IMAGE" && existentFile,
                then: Yup.string().required(<FormattedMessage
                    id="project.common.legendType.file.error" />),
                otherwise: Yup.string().notRequired()
            }).nullable(),
            description: Yup.string().when(["legendType", "existentFile"], {
                is: (legendType, existentFile) => legendType === "LEGEND_TYPE_IMAGE" && !existentFile,
                then: Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <FormattedMessage id="project.attachedFiles.description" />,
                    }} />),
                otherwise: Yup.string().notRequired()
            }).nullable()
        }

        this.props.activeLanguages.forEach(language => {
            formFieldsValidation[language] = Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <InternationalizationRender value={language} />,
                }}
            />)
            formFieldsValidation[language+"TableText"] = Yup.string().when("canJoin", {
                is: (canJoin) => canJoin,
                then: Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <InternationalizationRender value={language} />,
                    }} />),
                otherwise: Yup.string().notRequired()
            })
            formFieldsValidation[language+"TooltipText"] = Yup.string().when("canJoin", {
                is: (canJoin) => canJoin,
                then: Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <InternationalizationRender value={language} />,
                    }} />),
                otherwise: Yup.string().notRequired()
            })
        })

        const Schema = Yup.object().shape(formFieldsValidation);

        let allowChangeOpacityConfigurationParameter = false;
        if (this.props.allConfigurationParameters) {
            allowChangeOpacityConfigurationParameter = Object.values(this.props.allConfigurationParameters).filter(parameter =>
                parameter.code.indexOf("ALLOW_CONFIGURE_LAYERS_OPACITY") !== -1);
            if (allowChangeOpacityConfigurationParameter.length > 0) {
                allowChangeOpacityConfigurationParameter = allowChangeOpacityConfigurationParameter[0].value;
                allowChangeOpacityConfigurationParameter = (allowChangeOpacityConfigurationParameter === 'true');
            } else {
                allowChangeOpacityConfigurationParameter = true;
            }
        }

        let initialValues = {
            internalName: "",
            order: "",
            geometricLayerGroup: "",
            type: "",
            internalComments: "",
            serverUrl: "",
            initiallyVisible: false,
            version: "",
            legendType: "LEGEND_TYPE_NONE",
            legendTypeValue: "",
            fileId: null,
            existentFile: false,
            description: "",
            file: [],
            showAllLayersOnLegend: false,
            defaultOpacity: null,
            allowChangeOpacity: false,
            supportsGetFeatureInfo: false,
            informationFormatToUse: '',
            useProxy: false,
            styleFile: null,
            existentStyle: false,
            shapeFile: null,
            existentShape: false,
            coordinateSystem: "",
            useProxyAndroid: false
        };

        this.props.activeLanguages.forEach(actLan => {
            initialValues[actLan] = ""
        })

        if (this.props.geometricLayerToModify) {
            initialValues = {
                internalName: this.props.geometricLayerToModify.internalName,
                order: this.props.geometricLayerToModify.order,
                geometricLayerGroup: this.props.geometricLayerToModify.geometricLayerGroup.code.code,
                type: this.props.geometricLayerToModify.type.code,
                internalComments: this.props.geometricLayerToModify.internalComments,
                serverUrl: this.props.geometricLayerToModify.serverUrl,
                geometricLayerCode: this.props.geometricLayerToModify.code.code,
                initiallyVisible: this.props.geometricLayerToModify.initiallyVisible,
                version: this.props.geometricLayerToModify.version ? this.props.geometricLayerToModify.version : "",
                legendType: this.props.geometricLayerToModify.legendType,
                legendTypeValue: this.props.geometricLayerToModify.legendTypeValue ? this.props.geometricLayerToModify.legendTypeValue : "",
                fileId: this.props.geometricLayerToModify.legendTypeValue,
                showAllLayersOnLegend: this.props.geometricLayerToModify.showAllLayersOnLegend,
                defaultOpacity: this.props.geometricLayerToModify.defaultOpacity ? this.props.geometricLayerToModify.defaultOpacity * 100 : null,
                allowChangeOpacity: this.props.geometricLayerToModify.allowChangeOpacity,
                supportsGetFeatureInfo: this.props.geometricLayerToModify.supportsGetFeatureInfo,
                informationFormatToUse: this.props.geometricLayerToModify.informationFormatToUse || "",
                useProxy: this.props.geometricLayerToModify.useProxy,
                styleFile: null,
                existentStyle: true,
                existentShape: true,
                shapeFile: null,
                coordinateSystem: this.props.layerSource && this.props.layerSource.coordinateSystem,
                description: "",
                file: [],
                useProxyAndroid: this.props.geometricLayerToModify.useProxyAndroid
            }

            this.props.activeLanguages.forEach(actLan => {
                let splittedLanguage = actLan.split("-")[0].toLowerCase();
                initialValues[actLan] = getInternationalization(
                    splittedLanguage,
                    this.props.geometricLayerToModify.code.code,
                    this.props.allCodes
                ) || ""
            });

            if (initialValues.legendType === "LEGEND_TYPE_IMAGE") {
                initialValues.existentFile = true;
                let attachedFile = this.props.allGeneralAttachedFiles.filter(
                    // eslint-disable-next-line
                    generalAttachedFile => generalAttachedFile.id == this.props.geometricLayerToModify.legendTypeValue
                );
                if (attachedFile.length) {
                    attachedFile = attachedFile[0];
                    initialValues.legendTypeValue = `${attachedFile.description} - ${attachedFile.originalFilename}`;
                }
            }
        }

        let initAutocomplete = (setFieldValue) => {
            let allGeneralAttachedFiles = this.props.allGeneralAttachedFiles;
            autocomplete({
                minLength: 3,
                input: document.getElementById('legendTypeValue'),
                fetch: function (text, update) {
                    setFieldValue("fileId", null)
                    text = text.toLowerCase();
                    var suggestions = allGeneralAttachedFiles.filter(val => {
                        return val.fileFormat.image && (val.description.toLowerCase().includes(text) || val.originalFilename.toLowerCase().includes(text))
                    });
                    update(suggestions)
                },
                render: function (item, currentValue) {
                    var div = document.createElement("div");
                    div.textContent = `${item.description} - ${item.originalFilename}`;
                    div.style.zIndex = 999999999999
                    var modalDiv = document.getElementById('modal');
                    item.label = div.textContent;
                    modalDiv.appendChild(div);

                    return div;
                },
                onSelect: function (item, input) {
                    jQuery('div.autocomplete').hide();
                    setFieldValue("legendTypeValue", item.label);
                    setFieldValue("fileId", item.id);
                },
                customize: function (input, inputRect, container, maxHeight) {
                    container.style.zIndex = 999999
                }
            });
        }

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Schema}
                validate={(values) =>
                    validateGeneralAttachedFileField(values.legendType === "LEGEND_TYPE_IMAGE" && !values.existentFile, true, this.props.allFileFormat, values)
                }
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    this.props.setBackendErrors(null);
                    let geometricLayerGroup = this.props.listAllGeometricLayerGroup.filter(geometricLayerGroup =>
                        geometricLayerGroup.code.code.indexOf(values.geometricLayerGroup) !== -1)[0];
                    let version;
                    if (values.type === WMS_DATABASE_CODE || values.type === WMS_SHAPE_CODE) {
                        version = null;
                    } else {
                        if (values.version) {
                            version = values.version;
                        } else {
                            version = "1.3.0";
                        }
                    }
                    let legendTypeValue = !["LEGEND_TYPE_NONE", "LEGEND_TYPE_GET_LEGEND_GRAPHIC"].includes(values.legendType) ?
                        values.legendType === "LEGEND_TYPE_IMAGE" ? values.fileId : values.legendTypeValue
                        : null;
                    let serverUrl = values.type === WMS_DATABASE_CODE || values.type === WMS_SHAPE_CODE ? null : values.serverUrl
                    let defaultOpacity = values.defaultOpacity != null && values.defaultOpacity !== "" ? values.defaultOpacity / 100 : null
                    let internalComments = values.internalComments === "" ? null : values.internalComments
                    let informationFormatToUse = values.supportsGetFeatureInfo ? values.informationFormatToUse : null
                    let internalName = values.internalName === "" ? null : values.internalName
                    let formData = new FormData();
                    let internationalizatedText = "";
                    this.props.activeLanguages.forEach((value) => {
                        let splittedLanguage = value.split("-")[0].toLowerCase();
                        internationalizatedText += values[value];
                        internationalizatedText += internationalizatedTextSplit(splittedLanguage);
                    })
                    formData.append("internationalization", internationalizatedText)
                    if (internalName) {
                        formData.append("internalName", internalName)
                    }
                    formData.append("version", version)
                    formData.append("geometricLayerGroupId", geometricLayerGroup.id)
                    formData.append("order", values.order)
                    if (serverUrl) {
                        formData.append("serverUrl", serverUrl)
                    }
                    if (internalComments) {
                        formData.append("internalComments", internalComments)
                    }
                    formData.append("initiallyVisible", values.initiallyVisible)
                    formData.append("legendType", values.legendType)
                    if (legendTypeValue) {
                        formData.append("legendTypeValue", legendTypeValue)
                    }
                    formData.append("showAllLayersOnLegend", values.showAllLayersOnLegend)
                    if (defaultOpacity) {
                        formData.append("defaultOpacity", defaultOpacity)
                    }
                    formData.append("allowChangeOpacity", values.allowChangeOpacity)
                    formData.append("supportsGetFeatureInfo", values.supportsGetFeatureInfo)
                    if (informationFormatToUse) {
                        formData.append("informationFormatToUse", informationFormatToUse)
                    }
                    formData.append("useProxy", values.type === WMS_CODE && values.useProxy)
                    if (values.file.length > 0) {
                        formData.append("legendFileDescription", values.description)
                        formData.append("legendFile", values.file[0])
                    }
                    if ((values.type === WMS_DATABASE_CODE || values.type === WMS_SHAPE_CODE) && values.styleFile) {
                        formData.append("styleFile", values.styleFile)
                    }
                    if (values.type === WMS_SHAPE_CODE && values.shapeFile) {
                        formData.append("shapeFile", values.shapeFile)
                        if (values.coordinateSystem !== "") {
                            formData.append("coordinateSystem", values.coordinateSystem)
                        }
                    }
                    formData.append("useProxyAndroid", values.type === WMS_CODE && values.useProxyAndroid)
                    if (!this.props.geometricLayerToModify) {
                        formData.append("code", Math.random().toString(36).substring(2).toUpperCase() + '_LAYER')
                        formData.append("tableName", values.tableName)
                        formData.append("type", values.type)
                    }

                    if (this.props.geometricLayerToModify) {
                        this.props.dispatch(actions.modifyGeometricLayer(this.props.geometricLayerToModify.id, formData, () => {
                            this.props.dispatch(actions.findAllCodes(
                                () => {
                                    this.props.handleSubmit()
                                    resetForm();
                                }
                            ));
                        },
                            errors => {
                                this.props.setBackendErrors(errors);
                            }));
                    } else {
                        this.props.dispatch(actions.addGeometricLayer(formData, () => {
                            this.props.dispatch(actions.findAllCodes(
                                () => {
                                    this.props.handleSubmit();
                                    resetForm();
                                }
                            ));
                        },
                            errors => {
                                this.props.setBackendErrors(errors);
                            }));
                    }
                    setSubmitting(false);
                }}
            >
                {({ values, errors, setFieldError, setFieldValue, resetForm }) =>
                    <Modal id="modal" show={this.props.modalShow}
                        onHide={() => {
                            this.props.hideModalWindow();
                            resetForm();
                        }}
                        scrollable={true} size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.props.geometricLayerToModify ?
                                    <FormattedMessage id="project.elements.geometricLayer.layer.modify" />
                                    :
                                    <FormattedMessage id="project.elements.geometricLayer.layer.add" />
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="addGeometricLayerForm">
                                {errors ? this.setState({ formikErrors: errors }) : ""}
                                {document.getElementById('legendTypeValue') ? initAutocomplete(setFieldValue) : ""}
                                <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />

                                <div className="form-group">
                                    <label htmlFor="type"
                                        className={`font-weight-bold ${!this.props.geometricLayerToModify && 'required'}`}
                                    >
                                        <FormattedMessage id="project.elements.geometricLayer.layer.type" />
                                        {
                                            !this.props.geometricLayerToModify &&
                                            <>
                                                &nbsp;
                                                <sup id="typeHelp"
                                                    className="helpTooltip btn-link"
                                                    style={{ cursor: "pointer" }}
                                                    aria-hidden={true}
                                                    data-toggle="tooltip"
                                                    data-placement="left"
                                                    data-original-title={
                                                        this.props.intl.formatMessage({
                                                            id: "project.elements.geometricLayer.layer.type.help"
                                                        })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                                </sup>
                                            </>
                                        }
                                    </label>
                                    {this.props.geometricLayerToModify ?
                                        <span className="d-block" id="type"><InternationalizationRender value={values.type} /></span>
                                        :
                                        <Field as="select" id="type" name="type" className="form-control"
                                            onChange={event => {
                                                initTooltipHelp()
                                                setFieldValue('type', event.target.value);
                                                setFieldValue('useProxy', false);
                                                setFieldValue('useProxyAndroid', false);
                                            }}
                                        >
                                            <FormattedMessage id="project.common.selectOneOption">
                                                {(msg) => <option value="">{msg}</option>}
                                            </FormattedMessage>
                                            {this.geometricLayerTypeSelectorRender()}
                                        </Field>
                                    }
                                </div>
                                <ErrorMessage name="type" render={(msg) =>
                                    <div id="typeError" className="alert alert-danger alert-dismissible fade show"
                                        role="alert" hidden={typeof errors.type === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("type", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                {values.type === WMS_CODE &&
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field type="checkbox" id="useProxy" name="useProxy" />
                                            </div>
                                        </div>
                                        <label htmlFor="useProxy" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.geometricLayer.layer.useProxy" />
                                            &nbsp;
                                            <sup id="versionHelp"
                                                className="helpTooltip btn-link"
                                                style={{ cursor: "pointer" }}
                                                aria-hidden={true}
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                data-original-title={
                                                    this.props.intl.formatMessage({
                                                        id: "project.elements.geometricLayer.layer.useProxy.help"
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </sup>
                                        </label>
                                    </div>
                                }

                                {values.type === WMS_CODE &&
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field type="checkbox" id="useProxyAndroid" name="useProxyAndroid" />
                                            </div>
                                        </div>
                                        <label htmlFor="useProxyAndroid" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.geometricLayer.layer.useProxyAndroid" />
                                            &nbsp;
                                            <sup id="versionHelp"
                                                className="helpTooltip btn-link"
                                                style={{ cursor: "pointer" }}
                                                aria-hidden={true}
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                data-original-title={
                                                    this.props.intl.formatMessage({
                                                        id: "project.elements.geometricLayer.layer.useProxy.help"
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </sup>
                                        </label>
                                    </div>
                                }

                                {values.type === WMS_CODE ?
                                    <div className="form-group">
                                        <label htmlFor="version" className="font-weight-bold">
                                            <FormattedMessage id="project.elements.geometricLayer.layer.version" />
                                            &nbsp;
                                            <sup id="versionHelp"
                                                className="helpTooltip btn-link"
                                                style={{ cursor: "pointer" }}
                                                aria-hidden={true}
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                data-original-title={
                                                    this.props.intl.formatMessage({
                                                        id: "project.elements.geometricLayer.layer.version.help"
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </sup>
                                        </label>
                                        <Field as="select" id="version" name="version" className="form-control">
                                            <FormattedMessage id="project.common.selectOneOption">
                                                {(msg) => <option value="">{msg}</option>}
                                            </FormattedMessage>
                                            <option value="1.1.1">1.1.1</option>
                                            <option value="1.3.0">1.3.0</option>
                                        </Field>
                                    </div>
                                    :
                                    ""
                                }

                                {values.type === WMS_DATABASE_CODE &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="tableName"
                                                className={`font-weight-bold ${!this.props.geometricLayerToModify && 'required'}`}
                                            >
                                                <FormattedMessage id="project.elements.geometricLayer.layer.tableName" />
                                            </label>
                                            {this.props.geometricLayerToModify ?
                                                <span className="d-block" id="tableName">
                                                    {this.props.layerSource && this.props.layerSource.source}
                                                </span>
                                                :
                                                <Field id="tableName" name="tableName" className="form-control" />
                                            }
                                        </div>
                                        <ErrorMessage name="tableName" render={(msg) =>
                                            <div id="tableNameError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                hidden={typeof errors.tableName === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("tableName", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </>
                                }

                                {(values.type === WMS_CODE || values.type === WMS_DATABASE_CODE || values.type === WMS_SHAPE_CODE) &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="internalName"
                                                className={`font-weight-bold 
                                                    ${(!this.props.geometricLayerToModify
                                                        && (values.type !== WMS_DATABASE_CODE
                                                            || values.type !== WMS_SHAPE_CODE)) && 'required'}`
                                                }
                                            >
                                                <FormattedMessage id="project.elements.geometricLayer.layer.internalName" />
                                            </label>
                                            {!(this.props.geometricLayerToModify && (values.type === WMS_DATABASE_CODE || values.type === WMS_SHAPE_CODE)) ?
                                                <Field id="internalName" name="internalName" className="form-control" />
                                                :
                                                <span className="d-block" id="internalName">{values.internalName}</span>
                                            }
                                        </div>
                                        <ErrorMessage name="internalName" render={(msg) =>
                                            <div id="internalNameError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                hidden={typeof errors.internalName === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("internalName", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </>
                                }

                                {values.type === WMS_SHAPE_CODE && this.renderShapeFile(setFieldValue, values)}

                                {(values.type === WMS_DATABASE_CODE || values.type === WMS_SHAPE_CODE) && this.renderStyleFile(setFieldValue, values)}

                                {values.type !== "" && values.type !== OSM_CODE && values.type !== WMS_DATABASE_CODE && values.type !== WMS_SHAPE_CODE ?
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="serverUrl" className="font-weight-bold required">
                                                <FormattedMessage id="project.elements.geometricLayer.layer.serverUrl" />
                                            </label>
                                            <Field id="serverUrl" name="serverUrl" className="form-control" />
                                        </div>
                                        <ErrorMessage name="serverUrl" render={(msg) =>
                                            <div id="serverUrlError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                hidden={typeof errors.serverUrl === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("serverUrl", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </>
                                    :
                                    ""
                                }

                                {(values.type === WMS_CODE || values.type === WMS_DATABASE_CODE || values.type === WMS_SHAPE_CODE) &&
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field type="checkbox" id="supportsGetFeatureInfo" name="supportsGetFeatureInfo"
                                                    onChange={() => {
                                                        setFieldValue("supportsGetFeatureInfo", !values.supportsGetFeatureInfo);
                                                        setFieldValue("informationFormatToUse", values.type === WMS_DATABASE_CODE || values.type === WMS_SHAPE_CODE ?
                                                            'INFO_FORMAT_JSON' : ''
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <label htmlFor="supportsGetFeatureInfo" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.geometricLayer.layer.supportsGetFeatureInfo" />
                                        </label>
                                    </div>
                                }

                                {(values.type === WMS_CODE || values.type === WMS_DATABASE_CODE || values.type === WMS_SHAPE_CODE) && values.supportsGetFeatureInfo &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="informationFormatToUse" className="font-weight-bold required">
                                                <FormattedMessage id="project.elements.geometricLayer.layer.informationFormatToUse" />
                                            </label>
                                            <Field as="select" id="informationFormatToUse" name="informationFormatToUse" className="form-control"
                                                disabled={values.type === WMS_DATABASE_CODE || values.type === WMS_SHAPE_CODE}
                                            >
                                                <FormattedMessage id="project.common.selectOneOption">
                                                    {(msg) => <option value="">{msg}</option>}
                                                </FormattedMessage>
                                                {this.informationFormatToUseSelectorRender()}
                                            </Field>
                                        </div>
                                        <ErrorMessage name="informationFormatToUse" render={(msg) =>
                                            <div id="informationFormatToUseError" className="alert alert-danger alert-dismissible fade show"
                                                role="alert" hidden={typeof errors.informationFormatToUse === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("informationFormatToUse", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </>
                                }

                                <Field id="geometricLayerCode" name="geometricLayerCode"
                                    disabled={true} hidden={true} />

                                <div className="card" style={{ marginBottom: "1rem" }}>

                                    <h5 className="card-header">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.name" />
                                    </h5>

                                    <div className="card-body">
                                        <div className="form-group">
                                            {this.props.activeLanguages.map(actLan => {
                                                return (
                                                    <>
                                                        <div className="input-group mb-3 no-gutters">
                                                            <div className="input-group-prepend col-2">
                                                                <span className="input-group-text col-12 required">
                                                                    <InternationalizationRender value={actLan} />
                                                                </span>
                                                            </div>
                                                            <Field id={actLan} name={actLan} as="input" className="form-control" />
                                                        </div>
                                                        <ErrorMessage name={actLan} render={(msg) =>
                                                            <div id={`${actLan}Error`} className="alert alert-danger alert-dismissible fade show"
                                                                role="alert" hidden={typeof errors[actLan] === "undefined"}
                                                            >
                                                                <button type="button" className="close" aria-label="Close"
                                                                    onClick={() => setFieldError(actLan, undefined)}
                                                                >
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                                {msg}
                                                            </div>
                                                        }
                                                        />
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="order" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.order" />
                                    </label>
                                    <Field type="number" id="order" name="order" className="form-control" />
                                </div>
                                <ErrorMessage name="order" render={(msg) =>
                                    <div id="orderError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.order === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("order", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <Field type="checkbox" id="initiallyVisible" name="initiallyVisible" />
                                        </div>
                                    </div>
                                    <label htmlFor="initiallyVisible" className="btn input-group-text">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.initiallyVisible" />
                                    </label>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="geometricLayerGroup" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.geometricLayerGroup" />
                                    </label>
                                    <Field as="select" id="geometricLayerGroup" name="geometricLayerGroup" className="form-control">
                                        <FormattedMessage id="project.common.selectOneOption">
                                            {(msg) => <option value="">{msg}</option>}
                                        </FormattedMessage>
                                        {this.geometricLayerGroupSelectorRender()}
                                    </Field>
                                </div>
                                <ErrorMessage name="geometricLayerGroup" render={(msg) =>
                                    <div id="geometricLayerGroupError" className="alert alert-danger alert-dismissible fade show"
                                        role="alert" hidden={typeof errors.geometricLayerGroup === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("geometricLayerGroup", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="defaultOpacity">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.opacity.defaultOpacity" />
                                        &nbsp;
                                        <sup id="defaultOpacityHelp"
                                            className="helpTooltip btn-link"
                                            style={{ cursor: "pointer" }}
                                            aria-hidden={true}
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            data-original-title={
                                                this.props.intl.formatMessage({
                                                    id: "project.elements.geometricLayer.layer.opacity.defaultOpacity.help"
                                                })
                                            }
                                        >
                                            <FontAwesomeIcon icon={faQuestionCircle} />
                                        </sup>
                                    </label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">%</div>
                                        </div>
                                        <Field as="input" type="number" id="defaultOpacity" name="defaultOpacity" className="form-control" />
                                    </div>
                                </div>

                                {allowChangeOpacityConfigurationParameter &&
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field as="input" type="checkbox" id="allowChangeOpacity" name="allowChangeOpacity" />
                                            </div>
                                        </div>
                                        <label htmlFor="allowChangeOpacity" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.geometricLayer.layer.opacity.allowChangeOpacity.form" />
                                        </label>
                                    </div>
                                }

                                {this.legendTypeRender()}

                                {values.internalName && this.setState({ formikShowAllLayersOnLegend: values.legendType + values.internalName.includes(",") })}

                                {values.legendType === "LEGEND_TYPE_GET_LEGEND_GRAPHIC" && values.internalName && values.internalName.includes(",") ?
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field type="checkbox" id="showAllLayersOnLegend" name="showAllLayersOnLegend" />
                                            </div>
                                        </div>
                                        <label htmlFor="showAllLayersOnLegend" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.geometricLayer.layer.showAllLayersOnLegend" />
                                            &nbsp;
                                            <sup id="showAllLayersOnLegendHelp"
                                                className="helpTooltip btn-link"
                                                style={{ cursor: "pointer" }}
                                                aria-hidden={true}
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                data-original-title={
                                                    this.props.intl.formatMessage({
                                                        id: "project.elements.geometricLayer.layer.showAllLayersOnLegend.help"
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </sup>
                                        </label>
                                    </div>
                                    :
                                    ""
                                }

                                {values.legendType === "LEGEND_TYPE_URL" ?
                                    <>
                                        <div className="form-group">
                                            <label className="font-weight-bold required" htmlFor="legendTypeValue">
                                                <FormattedMessage id="project.common.legendType.url" />
                                            </label>
                                            <Field as="input" id="legendTypeValue" name="legendTypeValue" className="form-control" />
                                        </div>
                                        <ErrorMessage name="legendTypeValue" render={(msg) =>
                                            <div id="legendTypeValueError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                hidden={typeof errors.legendTypeValue === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("legendTypeValue", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </>
                                    :
                                    ""
                                }

                                {values.legendType === 'LEGEND_TYPE_IMAGE' ?
                                    <>
                                        <div className="custom-control custom-radio custom-control-inline mb-2">
                                            <Field type="radio"
                                                id="newImageRadio"
                                                name="existentFile"
                                                className="custom-control-input"
                                                value={false}
                                                checked={!values.existentFile}
                                                onChange={() => setFieldValue("existentFile", false)}
                                            />
                                            <label className="custom-control-label" htmlFor="newImageRadio">
                                                <FormattedMessage id="project.common.legendType.file.unexistent" />
                                            </label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <Field type="radio"
                                                id="existentImageRadio"
                                                name="existentFile"
                                                className="custom-control-input"
                                                value={true}
                                                checked={values.existentFile}
                                                onChange={() => setFieldValue("existentFile", true)}
                                            />
                                            <label className="custom-control-label" htmlFor="existentImageRadio">
                                                <FormattedMessage id="project.common.legendType.file.existent" />
                                            </label>
                                        </div>
                                    </>
                                    : ""
                                }

                                {values.legendType === 'LEGEND_TYPE_IMAGE' && values.existentFile ?
                                    <>
                                        <div className="form-group">
                                            <label className="font-weight-bold required" htmlFor="legendTypeValue">
                                                <FormattedMessage id="project.common.legendType.file" />
                                            </label>
                                            <Field as="input" id="legendTypeValue" name="legendTypeValue" className="form-control" />
                                        </div>
                                        <ErrorMessage name="fileId" render={(msg) =>
                                            <div id="fileIdError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                hidden={typeof errors.fileId === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("fileId", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </>
                                    :
                                    values.legendType === 'LEGEND_TYPE_IMAGE' && !values.existentFile ?
                                        <div className="card mb-2">
                                            <div className="card-body">
                                                <AddModifyGeneralAttachedFileFormFields
                                                    mustBeImage={true}
                                                    showTypeField={false}
                                                    setFieldValue={setFieldValue}
                                                    setFieldError={setFieldError}
                                                    errors={errors}
                                                    setHasAddedFiles={(hasAddedFiles) => this.setState({ hasAddedFiles })}
                                                    files={values.file}
                                                    resetValues={{}}
                                                    isLegendFile={false}
                                                />
                                            </div>
                                        </div>
                                        :
                                        ""
                                }

                                <div className="form-group">
                                    <label htmlFor="internalComments" className="font-weight-bold">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.internalComments" />
                                    </label>
                                    <Field as="textarea" id="internalComments" name="internalComments" className="form-control" />
                                </div>
                                <ErrorMessage name="internalComments" render={(msg) =>
                                    <div id="internalCommentsError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.internalComments === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("internalComments", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button id="createGeometricLayerSubmit" type="submit" className="btn btn-primary"
                                form="addGeometricLayerForm"
                                disabled={
                                    this.disableSubmitButton(values)
                                }
                            >
                                <FormattedMessage id="project.app.Body.accept" />
                            </button>
                            <button id="createGeometricLayerGroupCancel" type="button" className="btn btn-danger"
                                onClick={() => {
                                    this.props.hideModalWindow();
                                    resetForm();
                                }}
                            >
                                <FormattedMessage id="project.app.Body.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik >
        );
    }
}

AddModifyGeometricLayer.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    geometricLayerToModify: PropTypes.object,
    backendErrors: PropTypes.object,
    setBackendErrors: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired
}


export default connect(mapStateToProps)(injectIntl(AddModifyGeometricLayer));