import React from "react";
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors'
import * as actions from '../actions';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { Errors } from '../../common';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { Editor } from '@tinymce/tinymce-react';
import jQuery from 'jquery';
import InternationalizationRender, { getInternationalization } from "../../app/components/InternationalizationRender";
import PropTypes from 'prop-types';

const mapStateToProps = function (state) {
    return ({
        allCodes: selectors.getAllCodes(state),
        locale: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state)
    });
}

class AddModifyAttributeType extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formikErrors: null
        };

    }

    renderDataType = () => {
        if (this.props.allCodes) {
            let allDataType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('DATA_TYPE') !== -1);
            allDataType = allDataType.filter(dataType =>
                !["IMAGE", "FORMATTED_TEXT", "COLOR", "INTERNATIONALIZATED_TEXT", "LANGUAGE"].includes(dataType.code)
            );
            return (
                allDataType.map(dataType =>
                    <option value={dataType.code} key={dataType.code}>
                        {getInternationalization(this.props.locale, dataType.code, allDataType, this.props.activeLanguages)}
                    </option>
                )
            );
        } else {
            return (null)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (nextProps.attributeTypeToModify !== this.props.attributeTypeToModify) {
            return true;
        }

        if (nextProps.backendErrors !== this.props.backendErrors) {
            return true;
        }

        if (nextProps.setBackendErrors !== this.props.setBackendErrors) {
            return true;
        }

        if (nextProps.handleSubmit !== this.props.handleSubmit) {
            return true;
        }

        if (nextProps.hideModalWindow !== this.props.hideModalWindow) {
            return true;
        }

        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }
        return false;
    }

    componentDidUpdate() {
        if (this.state.formikErrors || this.props.backendErrors) {
            let container = jQuery('.modal-body');
            if (this.props.backendErrors) {
                container.scrollTop(0);
            } else {
                if (this.state.formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }
    }

    render() {

        let groupValuesSchema = {
            code: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.elements.attributes.code" />,
                }} />),
        }

        this.props.activeLanguages.forEach(actLan => {
            groupValuesSchema[actLan] = Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <InternationalizationRender value={actLan} />,
                }} />)
        });

        let formFieldsSchema = {
            attributeNameCode: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.elements.attributes.code" />,
                }} />),
            description: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.elements.description" />,
                }} />),
            dataType: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.elements.attributes.dataType" />,
                }} />),
            maxSize: Yup.number().when("dataType", {
                is: (val) => val === "TEXT",
                then: Yup.number()
                    .min(1, <FormattedMessage id="project.app.Body.field.positive"
                        values={{
                            field: <FormattedMessage id="project.elements.attributes.maxSize" />
                        }}
                    />)
                    .required(<FormattedMessage
                        id="project.app.Body.field.required"
                        values={{
                            field: <FormattedMessage id="project.elements.attributes.maxSize" />,
                        }} />),
                otherwise: Yup.number().notRequired()
            }),
            groupValues: Yup.array().when("dataType", {
                is: (val) => val === "MULTISELECT" || val === "SELECT",
                then: Yup.array().of(
                    Yup.object().shape(groupValuesSchema)
                )
            })
        }

        this.props.activeLanguages.forEach(actLan => {
            formFieldsSchema[actLan] = Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <InternationalizationRender value={actLan} />,
                }} />)
        })

        const Schema = Yup.object().shape(formFieldsSchema);

        // Prevent Bootstrap dialog from blocking focusin
        jQuery(document).on('focusin', function (e) {
            if (jQuery(e.target).closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
                e.stopImmediatePropagation();
            }
        });

        let initialValues = {}
        if (this.props.attributeTypeToModify) {
            initialValues = {
                description: this.props.attributeTypeToModify.description,
                dataType: this.props.attributeTypeToModify.dataType,
                maxSize: this.props.attributeTypeToModify.maxSize ? this.props.attributeTypeToModify.maxSize : "",
                attributeNameCode: this.props.attributeTypeToModify.code.code,
                groupValues: []
            }

            this.props.activeLanguages.forEach(actLan => {
                let splittedLanguage = actLan.split("-")[0].toLowerCase();
                initialValues[actLan] = getInternationalization(splittedLanguage, this.props.attributeTypeToModify.code.code, this.props.allCodes) || "";
                initialValues[actLan + "Help"] = getInternationalization(splittedLanguage, this.props.attributeTypeToModify.helpCode.code, this.props.allCodes) || "";
            })

            if (this.props.attributeTypeToModify.dataType === "SELECT" || this.props.attributeTypeToModify.dataType === "MULTISELECT") {
                this.props.attributeTypeToModify.valueGroupCode.listCode.forEach(code => {
                    let groupValue = {
                        code: code.code,
                        disabled: true
                    }
                    this.props.activeLanguages.forEach(actLan => {
                        let splittedLanguage = actLan.split("-")[0].toLowerCase();
                        groupValue[actLan] = getInternationalization(splittedLanguage, code.code, this.props.allCodes) || "";
                    })
                    initialValues.groupValues.push(groupValue)
                })
            }

        } else {
            initialValues = {
                description: "",
                dataType: "",
                maxSize: "",
                attributeNameCode: "",
                groupValues: []
            }

            this.props.activeLanguages.forEach(actLan => {
                initialValues[actLan] = "";
                initialValues[actLan + "Help"] = "";
            })
        }

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Schema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    this.props.setBackendErrors(null);
                    let attributeToInsert = {
                        "id": -1,
                        "description": values.description,
                        "dataType": values.dataType,
                        "helpCode": {
                            "id": -1,
                            "code": values.attributeNameCode + "_HELP_CODE",
                            "codeGroup": {
                                "id": -1,
                                "groupCode": "ATTRIBUTE_TYPE_HELP_GROUP_CODE"
                            },
                            "listInternationalizationDto": [{
                                "codeDto": {
                                    "id": -1,
                                    "code": values.attributeNameCode + "_HELP_CODE",
                                    "codeGroup": {
                                        "id": -1,
                                        "groupCode": "ATTRIBUTE_TYPE_HELP_GROUP_CODE"
                                    }
                                },
                                "language": "gl",
                                "description": values.helpGl
                            }, {
                                "codeDto": {
                                    "id": -1,
                                    "code": values.attributeNameCode + "_HELP_CODE",
                                    "codeGroup": {
                                        "id": -1,
                                        "groupCode": "ATTRIBUTE_TYPE_HELP_GROUP_CODE"
                                    }
                                },
                                "language": "es",
                                "description": values.helpEs
                            }, {
                                "codeDto": {
                                    "id": -1,
                                    "code": values.attributeNameCode + "_HELP_CODE",
                                    "codeGroup": {
                                        "id": -1,
                                        "groupCode": "ATTRIBUTE_TYPE_HELP_GROUP_CODE"
                                    }
                                },
                                "language": "en",
                                "description": values.helpEn
                            }]
                        }
                    }

                    attributeToInsert["code"] = {
                        "id": -1,
                        "code": values.attributeNameCode,
                        "codeGroup": {
                            "id": -1,
                            "groupCode": "GEOMETRIC_ELEMENT_NAME"
                        },
                        "listInternationalizationDto": []
                    }

                    this.props.activeLanguages.forEach(actLan => {
                        let splittedLanguage = actLan.split("-")[0].toLowerCase();
                        attributeToInsert.code.listInternationalizationDto.push({
                            "codeDto": {
                                "id": -1,
                                "code": values.attributeNameCode,
                                "codeGroup": {
                                    "id": -1,
                                    "groupCode": values.attributeNameCode
                                }
                            },
                            "language": splittedLanguage,
                            "description": values[actLan]
                        });
                        attributeToInsert.helpCode.listInternationalizationDto.push({
                            "codeDto": {
                                "id": -1,
                                "code": values.attributeNameCode,
                                "codeGroup": {
                                    "id": -1,
                                    "groupCode": values.attributeNameCode
                                }
                            },
                            "language": splittedLanguage,
                            "description": values[actLan + "Help"]
                        })
                    })

                    if (values.dataType === "TEXT") {
                        attributeToInsert["maxSize"] = values.maxSize;
                    }

                    if (values.dataType === "SELECT" || values.dataType === "MULTISELECT") {
                        let groupValues = []
                        values.groupValues.forEach(value => {
                            let groupValue = {
                                "id": -1,
                                "code": value.code,
                                "codeGroup": {
                                    "id": -1,
                                    "groupCode": values.attributeNameCode + "_CODE"
                                },
                                "listInternationalizationDto": []
                            }

                            this.props.activeLanguages.forEach(actLan => {
                                let splittedLanguage = actLan.split("-")[0].toLowerCase();
                                groupValue.listInternationalizationDto.push({
                                    "codeDto": {
                                        "id": -1,
                                        "code": values.attributeNameCode,
                                        "codeGroup": {
                                            "id": -1,
                                            "groupCode": values.attributeNameCode
                                        }
                                    },
                                    "language": splittedLanguage,
                                    "description": value[actLan]
                                })
                            })

                            groupValues.push(groupValue);
                        })
                        attributeToInsert["valueGroupCode"] = {
                            "id": -1,
                            "groupCode": values.attributeNameCode + "_GROUP_CODE",
                            "listCode": groupValues
                        }
                    }
                    if (this.props.attributeTypeToModify) {
                        this.props.dispatch(actions.modifyAttributeType(this.props.attributeTypeToModify.id, attributeToInsert, () => {
                            this.props.dispatch(actions.findAllCodes(() => {
                                this.props.handleSubmit();
                                resetForm();
                            },
                                errors => this.props.setBackendErrors(errors)))
                        }, errors => this.props.setBackendErrors(errors)));
                    } else {
                        this.props.dispatch(actions.addAttributeType(attributeToInsert, () => {
                            this.props.dispatch(actions.findAllCodes(() => {
                                this.props.handleSubmit();
                                resetForm();
                            }), errors => this.props.setBackendErrors(errors))
                        }, errors => this.props.setBackendErrors(errors)));
                    }
                    setSubmitting(false);
                }}
            >
                {({ values, errors, setFieldValue, setFieldError, resetForm }) =>
                    <Modal show={this.props.modalShow} onHide={this.props.hideModalWindow} scrollable={true} size="lg" enforceFocus={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.props.attributeTypeToModify ?
                                    <FormattedMessage id="project.elements.attributes.modify" />
                                    :
                                    <FormattedMessage id="project.elements.attributes.add" />
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="addAttributeTypeForm">
                                {errors ? this.setState({ formikErrors: errors }) : ""}
                                <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />

                                <div className="form-group">
                                    <label htmlFor="attributeNameCode" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.attributes.code" />
                                    </label>
                                    <Field id="attributeNameCode" name="attributeNameCode" className="form-control"
                                        disabled={this.props.attributeTypeToModify ? true : false}
                                    />
                                </div>
                                <ErrorMessage name="attributeNameCode" render={(msg) =>
                                    <div id="attributeNameCodeError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.attributeNameCode === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("attributeNameCode", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="card">
                                    <h5 className="card-header"><FormattedMessage id="project.elements.attributes.attributeName" /></h5>
                                    <div className="card-body">
                                        <div className="form-group">
                                            {this.props.activeLanguages.map(actLan => {
                                                return (
                                                    <>
                                                        <div className="input-group mb-3 no-gutters">
                                                            <div className="input-group-prepend col-2">
                                                                <span className="input-group-text col-12 required">
                                                                    <InternationalizationRender value={actLan} />
                                                                </span>
                                                            </div>
                                                            <Field id={actLan} name={actLan} as="input" className="form-control" />
                                                        </div>
                                                        <ErrorMessage name={actLan} render={(msg) =>
                                                            <div id={`${actLan}Error`} className="alert alert-danger alert-dismissible fade show"
                                                                role="alert" hidden={typeof errors[actLan] === "undefined"}
                                                            >
                                                                <button type="button" className="close" aria-label="Close"
                                                                    onClick={() => setFieldError(actLan, undefined)}
                                                                >
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                                {msg}
                                                            </div>
                                                        }
                                                        />
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="form-group">
                                    <label htmlFor="description" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.description" />
                                    </label>
                                    <Field id="description" name="description" as="textarea" className="form-control" />
                                </div>
                                <ErrorMessage name="description" render={(msg) =>
                                    <div id="descriptionError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.description === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("description", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                {this.props.attributeTypeToModify ?
                                    this.props.attributeTypeToModify.code.codeGroup.groupCode !== "CONFIGURATION_PARAMETERS" ?
                                        <div className="form-group">
                                            <label htmlFor="dataType" className="font-weight-bold required">
                                                <FormattedMessage id="project.elements.attributes.dataType" />
                                            </label>
                                            <Field as="select" id="dataType" name="dataType" className="form-control"
                                                disabled={this.props.attributeTypeToModify ? true : false}
                                            >
                                                <FormattedMessage id="project.common.selectOneOption">
                                                    {(msg) => <option value="">{msg}</option>}
                                                </FormattedMessage>
                                                {this.renderDataType()}
                                            </Field>
                                        </div>
                                        :
                                        ""
                                    :
                                    <div className="form-group">
                                        <label htmlFor="dataType" className="font-weight-bold required">
                                            <FormattedMessage id="project.elements.attributes.dataType" />
                                        </label>
                                        <Field as="select" id="dataType" name="dataType" className="form-control"
                                            disabled={this.props.attributeTypeToModify ? true : false}
                                        >
                                            <FormattedMessage id="project.common.selectOneOption">
                                                {(msg) => <option value="">{msg}</option>}
                                            </FormattedMessage>
                                            {this.renderDataType()}
                                        </Field>
                                    </div>
                                }

                                <ErrorMessage name="dataType" render={(msg) =>
                                    <div id="dataTypeError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.dataType === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("dataType", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                {values.dataType === "TEXT" ?
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="maxSize" className="font-weight-bold required">
                                                <FormattedMessage id="project.elements.attributes.maxSize" />
                                            </label>
                                            <Field id="maxSize" name="maxSize" type="number" className="form-control" />
                                        </div>

                                        <ErrorMessage name="maxSize" render={(msg) =>
                                            <div id="maxSizeError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                hidden={typeof errors.maxSize === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("maxSize", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </div>

                                    : ""
                                }

                                {values.dataType === "SELECT" || values.dataType === "MULTISELECT" ?
                                    <div>
                                        <div className="card">
                                            <h5 className="card-header">
                                                <FormattedMessage id="project.elements.attributes.values" />
                                            </h5>
                                            <div className="card-body">
                                                <FieldArray id="groupValues" name="groupValues" className="form-control"
                                                    render={arrayHelpers => (
                                                        <div>
                                                            {values.groupValues.map((value, index) => (
                                                                <div>
                                                                    <div key={index} className="form-group">
                                                                        <label htmlFor={`groupValues${index}.code`} className="font-weight-bold required">
                                                                            <FormattedMessage id="project.elements.attributes.code" />
                                                                        </label>
                                                                        <Field id={`groupValues${index}Code`} name={`groupValues[${index}].code`}
                                                                            className="form-control" disabled={value.disabled}
                                                                        />
                                                                    </div>
                                                                    <ErrorMessage name={`groupValues[${index}].code`} render={(msg) =>
                                                                        <div id={`groupValues${index}CodeError`}
                                                                            className="alert alert-danger alert-dismissible fade show"
                                                                            role="alert"
                                                                            hidden={typeof errors.groupValues[index].code === "undefined"}
                                                                        >
                                                                            <button type="button" className="close" aria-label="Close"
                                                                                onClick={() => setFieldError(`groupValues${index}.code`, undefined)}
                                                                            >
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                            {msg}
                                                                        </div>
                                                                    }
                                                                    />

                                                                    <div className="form-group">
                                                                        {this.props.activeLanguages.map(actLan => {
                                                                            return (
                                                                                <>
                                                                                    <div className="input-group mb-3 no-gutters">
                                                                                        <div className="input-group-prepend col-2">
                                                                                            <span className="input-group-text col-12 required">
                                                                                                <InternationalizationRender value={actLan} />
                                                                                            </span>
                                                                                        </div>
                                                                                        <Field id={`groupValues${index}${actLan}`} name={`groupValues[${index}].${actLan}`}
                                                                                            as="input" className="form-control"
                                                                                        />
                                                                                    </div>
                                                                                    <ErrorMessage name={`groupValues[${index}].${actLan}`} render={(msg) =>
                                                                                        <div id={`groupValues${index}${actLan}Error`}
                                                                                            className="alert alert-danger alert-dismissible fade show"
                                                                                            role="alert"
                                                                                            hidden={typeof errors.groupValues[index][actLan] === "undefined"}
                                                                                        >
                                                                                            <button type="button" className="close" aria-label="Close"
                                                                                                onClick={() => setFieldError(`groupValues[${index}].${actLan}`, undefined)}
                                                                                            >
                                                                                                <span aria-hidden="true">&times;</span>
                                                                                            </button>
                                                                                            {msg}
                                                                                        </div>
                                                                                    }
                                                                                    />
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                    <button className="btn btn-sm btn-secondary"
                                                                        type="button" id={"groupValue" + index} onClick={() => {
                                                                            arrayHelpers.remove(index);
                                                                        }}
                                                                    // disabled={value.disabled}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <div className="dropdown-divider"></div>
                                                                </div>
                                                            ))}
                                                            <button className="btn btn-secondary" type="button" id="addGroupValue"
                                                                onClick={() => {
                                                                    arrayHelpers.push({
                                                                        code: '', gl: '', es: '', en: '', disabled: false
                                                                    })
                                                                }}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    : ""}

                                {this.props.attributeTypeToModify ?
                                    <div className="card">
                                        <h5 className="card-header">
                                            <FormattedMessage id="project.elements.attributes.help" />
                                        </h5>
                                        <div className="card-body">
                                            {this.props.activeLanguages.map(actLan => {
                                                let fieldName = actLan + "Help";
                                                return (
                                                    <div className="form-group">
                                                        <label className="font-weight-bold" htmlFor={fieldName}>
                                                            <InternationalizationRender value={actLan} />
                                                        </label>
                                                        <Editor
                                                            id={fieldName}
                                                            initialValue={values[fieldName]}
                                                            init={{
                                                                height: 200,
                                                                menubar: false,
                                                                language: this.props.locale,
                                                                language_url: '/tinymce/' + this.props.locale + '.js',
                                                                plugins: [
                                                                    'advlist autolink lists link image charmap print preview anchor',
                                                                    'searchreplace visualblocks code fullscreen',
                                                                    'insertdatetime media table paste code help wordcount',
                                                                ],
                                                                toolbar: [
                                                                    'undo redo | formatselect | bold italic underline backcolor forecolor | link image | ' +
                                                                    'alignleft aligncenter alignright alignjustify | ' +
                                                                    'bullist numlist outdent indent | table | removeformat | help'
                                                                ]
                                                            }}
                                                            onEditorChange={(content, editor) => {
                                                                setFieldValue(fieldName, content);
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : ""
                                }
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button id="createAttributeTypeSubmit" type="submit" className="btn btn-primary"
                                form="addAttributeTypeForm"
                            >
                                <FormattedMessage id="project.app.Body.accept" />
                            </button>
                            <button id="createAttributeTypeCancel" type="button" className="btn btn-danger"
                                onClick={() => {
                                    this.props.hideModalWindow();
                                    resetForm();
                                }}
                            >
                                <FormattedMessage id="project.app.Body.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik>
        );
    }
}

AddModifyAttributeType.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    attributeTypeToModify: PropTypes.object,
    backendErrors: PropTypes.object.isRequired,
    setBackendErrors: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(AddModifyAttributeType);